import {React, Component, useState, useMemo} from 'react';
import ReactDOM from 'react-dom/client';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Reaptcha from 'reaptcha';
import { db, auth } from "../services/Firebase";
import {
addDoc,
collection,
setDoc,
doc,
onSnapshot,
orderBy,
query,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Navigate } from "react-router-dom";
import { unstable_composeClasses } from '@mui/material';


const NewPostResponseMessage = () => {
    const [captchaToken, setCaptchaToken] = useState(null);
    const captchaRef = useRef(null)
    const location = useLocation()
	const un = location.state != null ? location.state.username : ""
	const ui = location.state != null ? location.state.userId : ""
    const msgRef = location.state != null ? location.state.mesageReference : ""
    const [charCount, setCharCount] = useState(0)
    const [characterLimit] = useState(100);
    const [inputText, setInputText] = useState("");
    // console.log("un-"+un )
    // console.log("ui-"+ui )
    // console.log("ref-"+msgRef )
	const [isSignedIn, setSignedIn] = useState(false)
	const [users, setUsers] = useState([]);
    const [messageReference, setMessageReference] = useState((msgRef) ? msgRef : "");
	const [recieverData, setRecieverData] = useState( 
											(ui !='"' && un!="") ?
												{
													username: un, 
													userId: ui,
												}
											: null
											);
	const [chatMessage, setChatMessage] = useState("");
	const [allMessages, setAllMessages] = useState([]);
	const [userid, setUserId] = useState("")
    const [username, setUserName] = useState("")
    const [userPhoto, setUserPhoto] = useState("")
	const user = auth.currentUser;
	const navigate = useNavigate();
	var displayPhoto = ""
	var displayName = ""
	displayPhoto = localStorage.getItem("displayPhoto") ? localStorage.getItem("displayPhoto") : ""
	displayName = localStorage.getItem("displayName") ? localStorage.getItem("displayName") : ""
	useEffect(() => {
		const unsub = onSnapshot(collection(db, "users"), (snapshot) => {
		setUsers(snapshot.docs.map((doc) => doc.data()));
		});
		return unsub;
	}, []);


	useEffect(() => {
		onAuthStateChanged(auth, (user) => {   
			if (user) {
                console.log("User id ", user.uid)
                console.log("Receiver id ", recieverData.userId)
                if((user.uid==recieverData.userId)){
                    console.log("User id and receiver id are the same")
                    navigate('/posts')
                }
                console.log("user---", user.uid)
                if(location.state != null)
				{
					setRecieverData({userId:location.state.userId, username: location.state.username, })
                    setMessageReference(location.mesageReference, location.mesageReference, "")
                }
				setSignedIn(true);
				setUserId(user.uid ? user.uid : "")
				// setUserName(user.displayName ? user.displayName : "")
				// setUserPhoto(user.photoURL ? user.photoURL : "")
				// console.log("User is signed in " + user.uid)				
				/*
                async function fetchData(){
					try {
						if (recieverData) {
							console.log(recieverData)
							const unsub = onSnapshot(
								query(
								collection(
									db,
									"users",
									user?.uid,
									"chatusers",
									recieverData?.userId,
									"messages"
								),
                                orderBy("timestamp")
                                ),
                                (snapshot) => {
                                    setAllMessages(
                                        snapshot.docs.map((doc) => ({
                                        id: doc.id,
                                        messages: doc.data(),
                                        }))
                                    );
                                }
						    );
						    return unsub;
						}
					} catch(error) {
						console.log(error)
					}
				}
				fetchData()
                .then((data => {
                    navigate("/post/message/new");
                }))
                */ 
			} else {
				// User is signed out
				setSignedIn(false);
			    console.log("Username", username)
                const uid = user ? user.uid : "" ;
                navigate("/signin", {
                state: {
                    userId: recieverData.userId,
                    username: recieverData.username,
                    mesageReference: msgRef
                }
                });
			}
		});	
	}, [recieverData?.userId]);

    const verify = () =>{
        captchaRef.current.getResponse().then(res => {
            setCaptchaToken(res)
        })
    }

    const handleTextAreaChange = event => {
        setInputText(event.target.value);
        setCharCount(event.target.value.length)
        setChatMessage(event.target.value)
      };

    const sendMessage = async (e) => {
        e.preventDefault();
	    if(chatMessage=="") return	//if message is empty do not send mesage
	    try {
            if (user && recieverData) {
 
                const sendingToData = {userid: recieverData.userId}
                const sendingFromData = {userid: user.uid}
                console.log(`I : ${user.uid} am sending to ${recieverData.userId}`)
                //console.log(`RECEIVER : ${recieverData.userId}` )
                await setDoc(doc(db, "users", user.uid, "chatusers", recieverData.userId), sendingToData);
                await addDoc(
                collection(
                    db,
                    "users",
                    user.uid,
                    "chatusers",
                    recieverData.userId,
                    "messages"
                ),
                {
                    username: user.displayName,
                    messageUserId: user.uid,
                    message: chatMessage,
                    timestamp: new Date(),
                }
                );
                await setDoc(doc(db, "users", recieverData.userId, "chatusers", user.uid), sendingFromData);
                await addDoc(
                collection(
                    db,
                    "users",
                    recieverData.userId,
                    "chatusers",
                    user.uid,
                    "messages"
                ),
                {
                    username: user.displayName,
                    messageUserId: user.uid,
                    message: chatMessage,
                    timestamp: new Date(),
                }
                );
		}
        } catch (error) {
            console.log(error);
        }
	    setChatMessage("");
        navigate("/messages/1", {
            state: {
                userId: recieverData.userId,
                username: recieverData.username,
                mesageReference: messageReference
            }
            });
    }
    return (
        <>
        <div className="xch-main-header xch-container ">
                <div className="xch-header-flex">
                    <div className="xch-header-a"></div>
                    <h2 className="text-center xch-header-c">New Message</h2>
                    <div className="xch-header-b"></div>
                </div>
            </div>
        
        <Container>
            <Form onSubmit={sendMessage}>
               

                <Row className="xch-contact">
                <Col>
                        <Form.Group className="mb-3" controlId="formBasicTextArea">
                            {/* <Form.Label>Message</Form.Label> */}
                            <Form.Label className='xch-label'>Message &nbsp;<span className="xch-sm-txt">({charCount}/100)</span></Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="enter message" 
                             value={inputText} 
                             onChange={handleTextAreaChange} 
                             isInvalid={(inputText.length > characterLimit)}// value={newPostText}
                             maxlength="100"
                            />
                        </Form.Group>
                        
                        {/* <Form.Group className="mb-3" controlId="formBasicRecaptcha">
                            <Reaptcha 
                            sitekey={process.env.REACT_APP_SITE_KEY}
                            ref={captchaRef}
                            onVerify={verify} 
                            theme="light"
                            classname="mb-3"
                            >
                            </Reaptcha>
                        </Form.Group> */}
        
                        <Button variant="primary" type="submit">
                            Send Message
                        </Button>
                </Col>
            </Row>  
            </Form>  
        </Container>
        </>
    )
}


    



export default NewPostResponseMessage;