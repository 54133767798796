import React, { useState, useEffect } from 'react';
//import TfLogo from "../tf_logo.png"
import CxLogo from "../cx_logo_70_h.png"
import { Link } from 'react-router-dom';
import '../App.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { PersonCircle } from 'react-bootstrap-icons';
import { db, auth } from "../services/Firebase";
import { TicketPerforatedFill } from 'react-bootstrap-icons';
import { Calendar } from 'react-bootstrap-icons';
import { Newspaper } from 'react-bootstrap-icons';
import Avatar from 'react-avatar';
import {
	addDoc,
	updateDoc,
	doc,
	getDoc,
	getDocs,
	collection,
	where,
	setDoc,
	onSnapshot,
	orderBy,
	query,
	} from "firebase/firestore";


function Navi(){
    const [isSignedIn, setSignedIn] = useState(false)
    const [showNavIcon, setShowNavIcon] = useState(false)
    const [username, setUserName] = useState("")
    const [userPhoto, setUserPhoto] = useState("")
	const [userEmail, setUserEmail] = useState("")
    const [navDrop, setNavDrop] = useState(false)
    const [photoUrl, setPhotoUrl] = React.useState('');

    onAuthStateChanged(auth, async (user) => {
    if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        setSignedIn(true);
        //setUserName(user.displayName ? user.displayName : "")
		//setUserPhoto(user.photoURL ? user.photoURL : "")

        const myRef = collection(db, "users");
			const q = query(myRef, where("email", "==", user.email));
			const docsSnap = await getDocs(q);
			console.log(docsSnap.docs.length)
			if (docsSnap.docs.length > 0) {
                docsSnap.forEach(async thisDoc => {
                    setUserPhoto(thisDoc.data().photoURL ?  thisDoc.data().photoURL : "")
					console.log(thisDoc.data());
                })
            }
        // ...
    } else {
        // User is signed out
        setSignedIn(false);
        // ...
    }
    });


    useEffect( () => {
		function getWindowSize() {
			const {innerWidth, innerHeight} = window;
			setShowNavIcon( (innerWidth  < 768) ? true : false )
            setNavDrop( (innerWidth  < 992) ? false : true )
		  }
		  getWindowSize()
	}, []); 
    

    const navDropdownTitle = (
        <>
        {  userPhoto ? ( <img className="avatar-top" src={userPhoto ? userPhoto : ""}/> ) : (<Avatar name={username} size="12" />)}
        </>
    );

    return(
    <>
    {/* <Container fluid>
        <div className="tf-nav">
            <div className="nav-a">
                <div className="tf-logo">
                    <img src={TfLogo}></img>
                </div>
            </div>
            <div className="tf-links">
                <div className="tf-link-events"><a href="https://www.ticketfederation.com/upcoming-events/" >{ showNavIcon ? (<TicketPerforatedFill/>) : (<span>Upcoming Events</span>) } </a></div>
                <div className="tf-link-schedule"><a href="https://www.ticketfederation.com/carnival-schedule/" >{ showNavIcon ? (<Calendar/>) : (<span>Carnival Schedule</span>)}</a></div>
                <div className="tf-link-news"><a href="https://www.ticketfederation.com/latest-news/" >{ showNavIcon ? (<Newspaper/>) : (<span>Latest News</span>)}</a></div>
            </div>
        </div>
    </Container> */}
    
    <Navbar bg="white" expand="lg">
      <Container>
        <Navbar.Brand href="/posts">
            <div className="nav-a">
                <div className="tf-logo">
                    <img src={CxLogo}></img>
                    {/* &nbsp;<span className="">Carnival Xchange</span> */}
                </div>
            </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="xch-b">
            <div className="xch-nav-a"></div>
            <Nav.Link href="/messages/1">Messages</Nav.Link>
            <Nav.Link href="/posts">Listings</Nav.Link>
            {/* <Nav.Link href="/about">About</Nav.Link> */}
            <Nav.Link href="/contact">Contact</Nav.Link>
            <Nav.Link href="/faq">FAQ</Nav.Link>
            { isSignedIn==true ? (
                <>
                    {
                        navDrop==true ? (
                            <NavDropdown title={navDropdownTitle} id="basic-nav-dropdown">
                                <NavDropdown.Item href="/post/new">New Listing</NavDropdown.Item>
                                <NavDropdown.Item href="/myposts">My Listings</NavDropdown.Item>
                                <NavDropdown.Item href="/user/profile/edit">Edit Profile</NavDropdown.Item>
                                <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
                            </NavDropdown>
                        ):(
                            <>
                                {navDropdownTitle}
                                <Nav.Link className="xch-sm-menu" href="/post/new">New Listing</Nav.Link>
                                <Nav.Link className="xch-sm-menu" href="/myposts">My Listings</Nav.Link>
                                <Nav.Link className="xch-sm-menu" href="/user/profile/edit">Edit Profile</Nav.Link>
                                <Nav.Link className="xch-sm-menu" href="/logout">Logout</Nav.Link>
                            </>
                        )
                    }
                    
                </> 
            ):(
                <>
                        <Nav.Link href="/signin"><span className="">Sign in </span></Nav.Link>
                        {/* <div className="xch-spacer">|</div> */}
                        {/* <NavDropdown.Divider />
                        <Nav.Link href="/signup">Sign up</Nav.Link> */}
                    </>
                    
                )
            }
            <div className="xch-nav-c"></div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    
    
    
    
    
    
    
    
    

</>
)
};

export default Navi