import * as React from "react";
import Avatar from "@mui/material/Avatar";
import MButton from "@mui/material/Button";
import Button from 'react-bootstrap/Button'
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { db, auth, storage } from "../services/Firebase";
import {Component, useState, useMemo} from 'react';
import { useEffect, useContext, use } from 'react';
// import { GoogleAuthProvider } from "firebase/auth";
import { getAuth, signInWithPopup, GoogleAuthProvider, signInWithRedirect, FacebookAuthProvider } from "firebase/auth";
import { PersonCircle } from 'react-bootstrap-icons';
import { Facebook } from 'react-bootstrap-icons';
import { Google } from 'react-bootstrap-icons';
import {
	addDoc,
	updateDoc,
	doc,
	getDoc,
	getDocs,
	collection,
	where,
	setDoc,
	onSnapshot,
	orderBy,
	query,
	} from "firebase/firestore";
	import { Navigate, useLocation } from "react-router-dom";



const theme = createTheme();

function SignIn(){ 
	const location = useLocation()
	console.log(location)
	const un = location.state != null ? location.state.username : ""
	const ui = location.state != null ? location.state.userId : ""
	const msgRef = location.state != null ? location.state.mesageReference : ""
	console.log("un-"+un )
    console.log("ui-"+ui )
    console.log("ref-"+msgRef )
	const auth = getAuth();
	const [email, setEmail] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [photoUrl, setPhotoUrl] = React.useState('');
	const [displayName, setDisplayName] = React.useState('');
	const [goTo, setGoTo] = React.useState('/posts');
	const [messageReference, setMessageReference] = useState((msgRef!="") ? msgRef : "");
	const [msgUserId, setMsgUserId] = useState((ui!="") ? ui : "");
	const [msgUserName, setMsgUserName] = useState((un!="") ? un : "");

	const navigate = useNavigate();
	const handleSubmit = async (event) => {
		event.preventDefault();
		signInWithEmailAndPassword(auth, email, password)
		.then((userCredential) => {
			// Signed in
			const user = userCredential.user;
			// console.log(user)
			// localStorage.setItem("displayName", user.displayName ? user.displayName : "")
			// localStorage.setItem("displayPhoto", user.displayPhoto ? user.displayPhoto : "")

			navigate("/messages/1");
			// ...
		})
		.catch((error) => {
			const errorCode = error.code;
			const errorMessage = error.message;

			alert(errorMessage);
		});
	};

	function handleGoogleSignIn(){
		const provider = new GoogleAuthProvider();
		signInWithPopup(auth, provider)
		.then(async (result) => {
			// This gives you a Google Access Token. You can use it to access the Google API.
			const credential = GoogleAuthProvider.credentialFromResult(result);
			const token = credential.accessToken;
			const user = result.user;
			console.log(user)
			var data={
				email: user.email ? user.email : "",
				userId: user.uid ? user.uid : ""
			}
			
			const myRef = collection(db, "users");
			const q = query(myRef, where("email", "==", user.email));
			const docsSnap = await getDocs(q);
			console.log(docsSnap.docs.length)
			if (docsSnap.docs.length > 0) {
				console.log("Exists--------------------")
				docsSnap.forEach(async thisDoc => {
					const docRef = doc(db, "users", thisDoc.id);
					console.log(thisDoc.data());
					console.log(thisDoc.id);
					data.userId = thisDoc.id
					if(thisDoc.data().photoURL ==""){data.photoURL = (user.photoURL ? user.photoURL : "")}
					if(thisDoc.data().username ==""){data.username = (user.username ? user.username : "")}
					if(thisDoc.data().displayName ==""){data.displayName = (user.displayName ? user.displayName : "")}
					await updateDoc(docRef, data)
					.then(doco => {
						console.log(doco)
						localStorage.setItem("displayName", user.displayName ? user.displayName : "")
						localStorage.setItem("displayPhoto", user.photoURL ? user.photoURL : "")
						localStorage.setItem("username", user.displayName ? user.displayName : "")
						console.log("LOCATION")
						console.log(location.state)
						if(msgUserId!="" && msgUserName!="" && messageReference!=""){
							navigate("/post/message/new", {
								state: {
									userId: msgUserId,
									username: msgUserName,
									mesageReference: messageReference
								}
							});
						}else{
							navigate(goTo);
						}
					})
				})
			} else {
				console.log("Does Not Exist --------------------")
				//const dbRef = collection(db, "users")
				await setDoc(doc(db, "users", user.uid), data);
				localStorage.setItem("displayName", user.displayName ? user.displayName : "")
				localStorage.setItem("displayPhoto", user.photoURL ? user.photoURL : "")
				localStorage.setItem("username", user.displayName ? user.displayName : "")
				console.log(location.state)
				if(msgUserId!="" && msgUserName!="" && messageReference!=""){
					navigate("/post/message/new", {
						state: {
							userId: msgUserId,
							username: msgUserName,
							mesageReference: messageReference
						}
					});
				}else{
					navigate(goTo);
				}		
			}
		}).catch((error) => {
			// Handle Errors here.
			const errorCode = error.code;
			const errorMessage = error.message;
			console.log(error)
			// The email of the user's account used.
			//const email = error.customData.email;
			// The AuthCredential type that was used.
			const credential = GoogleAuthProvider.credentialFromError(error);
			// ...
		});
	}

	function handleFacebookSignIn(){
		const provider = new FacebookAuthProvider();
		signInWithPopup(auth, provider)
		.then(async (result) => {
			// The signed-in user info.
			const user = result.user;
			console.log(user)
			var data={
				email: user.email ? user.email : "",
				userId: user.uid ? user.uid : ""
			}
			
			const myRef = collection(db, "users");
			const q = query(myRef, where("email", "==", user.email));
			const docsSnap = await getDocs(q);
			console.log(docsSnap.docs.length)
			if (docsSnap.docs.length > 0) {
				console.log("Exists--------------------")
				docsSnap.forEach(async thisDoc => {
					const docRef = doc(db, "users", thisDoc.id);
					console.log(thisDoc.data());
					console.log(thisDoc.id);
					data.userId = thisDoc.id
					if(thisDoc.data().photoURL ==""){data.photoURL = (user.photoURL ? user.photoURL : "")}
					if(thisDoc.data().username ==""){data.username = (user.username ? user.username : "")}
					if(thisDoc.data().displayName ==""){data.displayName = (user.displayName ? user.displayName : "")}
					await updateDoc(docRef, data)
					.then(doco => {
						console.log(doco)
						localStorage.setItem("displayName", user.displayName ? user.displayName : "")
						localStorage.setItem("displayPhoto", user.photoURL ? user.photoURL : "")
						localStorage.setItem("username", user.displayName ? user.displayName : "")
						console.log("LOCATION")
						console.log(location.state)
						if(msgUserId!="" && msgUserName!="" && messageReference!=""){
							navigate("/post/message/new", {
								state: {
									userId: msgUserId,
									username: msgUserName,
									mesageReference: messageReference
								}
							});
						}else{
							navigate(goTo);
						}
					})
				})
			} else {
				console.log("Does Not Exist --------------------")
				//const dbRef = collection(db, "users")
				await setDoc(doc(db, "users", user.uid), data);
				localStorage.setItem("displayName", user.displayName ? user.displayName : "")
				localStorage.setItem("displayPhoto", user.photoURL ? user.photoURL : "")
				localStorage.setItem("username", user.displayName ? user.displayName : "")
				console.log(location.state)
				if(msgUserId!="" && msgUserName!="" && messageReference!=""){
					navigate("/post/message/new", {
						state: {
							userId: msgUserId,
							username: msgUserName,
							mesageReference: messageReference
						}
					});
				}else{
					navigate(goTo);
				}		
			}
		})
		.catch((error) => {
			// Handle Errors here.
			const errorCode = error.code;
			const errorMessage = error.message;
			console.log(error)
			// The email of the user's account used.
			// const email = error.customData.email;
			// The AuthCredential type that was used.
			const credential = FacebookAuthProvider.credentialFromError(error);

			// ...
		});

	}




return (
	<ThemeProvider theme={theme}>
	<Container component="main" maxWidth="xs">
		<CssBaseline />
		<Box
		sx={{
			marginTop: 8,
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		}}
		>
		<Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
			{/* <LockOutlinedIcon /> */}
		</Avatar>
		<Typography component="h1" variant="h5">
			Sign in
		</Typography>
		<Box
			component="form"
			onSubmit={handleSubmit}
			noValidate
			sx={{ mt: 1 }}
		>
			{/* <TextField
			margin="normal"
			required
			fullWidth
			id="email"
			label="Email Address"
			name="email"
			autoComplete="email"
			autoFocus
			value={email}
			onChange={(e) => setEmail(e.target.value)}
			/>
			<TextField
			margin="normal"
			required
			fullWidth
			name="password"
			label="Password"
			type="password"
			id="password"
			autoComplete="current-password"
			value={password}
			onChange={(e) => setPassword(e.target.value)}
			/>
			<FormControlLabel
			control={<Checkbox value="remember"
			color="primary" />}
			label="Remember me"
			/>
			<MButton
			type="submit"
			fullWidth
			variant="contained"
			sx={{ mt: 3, mb: 2 }}
			>
			Sign In
			</MButton> */}


			<Container>
					<div>
						<div>
							<Button onClick={() => {
								handleGoogleSignIn();
								}}
								className="xch-sign-btn xch-btn-google" variant="outline-secondary lg"><div className="xch-btn">
								<span className="xch-btn-left"><Google className="xch-btn-icon"/></span><span  className="xch-btn-right">Sign in with Google</span>
							</div></Button>
						</div>
						<div>
							<Button onClick={() => {
								handleFacebookSignIn();
								}}
								className="xch-sign-btn xch-btn-facebook" variant="outline-secondary lg"><div className="xch-btn">
									<span className="xch-btn-left"><Facebook className="xch-btn-icon"/></span><span className="xch-btn-right">Sign in with Facebook</span>
								</div></Button>
						</div>
					</div>
			</Container>
			{/* <Grid container>
				
				<Grid item xs>
					<Link href="#" variant="body2">
					Forgot password?
					</Link>
				</Grid>
				<Grid item>
					<Link href="/Signup" variant="body2">
					{"Don't have an account? Sign Up"}
					</Link>
				</Grid>
			</Grid> */}
		</Box>
		</Box>
		
	</Container>
	</ThemeProvider>
);
}

export default SignIn;
