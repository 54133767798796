import {React, Component, useState, useMemo} from 'react';
import ReactDOM from 'react-dom/client';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { ArrowLeftCircleFill } from 'react-bootstrap-icons';
import { db, auth, storage} from "../services/Firebase";
import { ref, getStorage, getDownloadURL, uploadBytesResumable, deleteObject } from "firebase/storage";
import { useAuthContext } from '../context/auth';
import { onAuthStateChanged } from "firebase/auth";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

import {
    collection,
    addDoc,
    getDoc,
    doc,
    updateDoc,
    onSnapshot,
    serverTimestamp,
    orderBy,
    query
} from 'firebase/firestore';

const EditProfile = () => {
    const [imgUrl, setImgUrl] = useState(null);
    const [isSignedIn, setSignedIn] = useState(false)
    const [progresspercent, setProgresspercent] = useState("");
    const { user } = useAuthContext()
    const [newPostCategory, setNewPostCategory] = useState("")
    const [newPostText, setNewPostText] = useState("")
    const [newPostImage, setNewPostImage] = useState("")
    const [posts, setPosts] = useState([])
    const dataRef = collection(db, "posts")
    const navigate = useNavigate();
    const [userid, setUserId] = useState("")
    const [postid, setPostId] = useState("")
    const [username, setUserName] = useState("")
    const [fulllName, setFullName] = useState("")
    const [userPhoto, setUserPhoto] = useState("")
    const [selectedCategory, setSelectedCategory] = useState("")
    const [postImage, setPostImage] = useState("")
    const [postRef, setPostRef] = useState("")
    const [canAddImage, setCanAddImage] = useState(0)
    const [hasChanged, sethasChanged] = useState(false)
    const [saved, setSaved] = useState(false)

    useEffect(() =>{ 
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid;
                setSignedIn(true);
                setUserId(user.uid ? user.uid : "")
                // setUserName(user.displayName ? user.displayName : "")
                // setUserPhoto(user.photoURL ? user.photoURL : "")
                console.log("User is signed in " + user.uid)
                console.log("User is signed in " + user.uid)
            
 
                async function fetchData(){
                    console.log("user id: " + uid)
                    const windowPath = window.location.pathname ? window.location.pathname : ""
                    const sliced_path = windowPath.slice(1)
                    const pathArray = sliced_path.split("/");
                    let post_id = pathArray[1] ? pathArray[1] : "";
                    setPostId(post_id);
                    console.log("post id: " + post_id)
                    const docRef = doc(db, "users", uid);
                    const docSnap = await getDoc(docRef);
                    try {
                        const docSnap = await getDoc(docRef);
                        if(docSnap.exists()) {
                            console.log(docSnap.data());
                            setUserName(docSnap.data().displayName ? docSnap.data().displayName : "")
                            setUserPhoto(docSnap.data().photoURL ? docSnap.data().photoURL : "")
                        } else {
                            console.log("Document does not exist")
                        }
                    } catch(error) {
                        console.log(error)
                    }
                }
        fetchData();
        } else {
            // User is signed out
            setSignedIn(false);
            navigate("/signin");
        }
        });
    }, [imgUrl]);

    const handleNameChange = event => {
        setUserName(event.target.value);
      };
    
    const notify = () => toast("Saved Successfully!");

    const saveProfile = async (e) => {
        e.preventDefault()
        const file = e.target[1]?.files[0] ? e.target[1]?.files[0] : ""
        const name = e.target[0].value ? e.target[0].value : ""
        if(name==""){return}
        const docRef = doc(db, "users", userid);
        var data = {
            displayName:name,
            username:name
        };
        
        function updateProfileImage(fileUrl){
            console.log("File URL: "+fileUrl)
            if(fileUrl != ""){data.photoURL = fileUrl}
            const storageRef = ref(storage, `files/${userid}/avatar/${file.name}`);
            console.log(data)
            console.log("------------------------")
            console.log(storageRef)
            console.log("------------------------")
            
            updateDoc(docRef, data)
            .then(docRef => {
                console.log("Updated")
                notify()
                // navigate(`/post/${postid}`) 
            })
            .catch(error => {
                console.log(error);
            })
        }

        if( file !="" )
        {
            // ################ upload the new file first and get the reference to it
            const storageRef = ref(storage, `files/${userid}/avatar/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on("state_changed",
            (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                if(progress ==100){
                    const notify = () => toast("Saved Successfully!");
                }
            },
            (error) => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                    // collection(db, "users" )
                    setImgUrl(downloadURL ? downloadURL : "")
                    updateProfileImage(downloadURL)
                });
            }
            );
        }
        else
        {
            updateProfileImage("")
        }

    }

        return (
            <>
            <div className="xch-main-header">
                <div className="xch-header-flex">
                    <div className="xch-header-a"></div>
                    <h2 className="text-center xch-header-c">Edit Profile</h2>
                    <div className="xch-header-b"></div>
                </div>
            </div>
            
            <Container>
                <Row className="xch-contact">
                    <Col>
                    <ToastContainer 
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                    />
                    <div>
                        <Form onSubmit={saveProfile}>
                                {/* elemnt 0 */}
                                <Form.Group  as={Col}  className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Display Name</Form.Label>
                                    <Form.Control type="text" placeholder="" 
                                     value={username}
                                     onChange={handleNameChange} 
                                    />
                                    <Form.Text className="text-muted text-center">This name will be displayed on your posts and messages.</Form.Text>
                                </Form.Group>

                                 <div className="xch-label xch-label-border">Avatar photo</div>
                                 <div className="xch-img-edit">
                                    <div className="xch-profile-avatar">
                                        { (userPhoto != "") ? (<img width="50" height="50" src={userPhoto}/>) : (<></>)}
                                    </div>
                                    <div className="xch-img-name"></div>
                                    <div className="xch-img-size"></div>
                                    <div className="xch-img-action">
                                        
                                        {/* {userPhoto != "" ? (
                                            <Button variant="danger" size="sm" 
                                                onClick={() => {deleteCurrentImage(postImage, postRef);
                                                }}>Delete
                                            </Button>
                                        )
                                        : (<>
                                            <Button disabled variant="light" size="sm" >Delete</Button>
                                            </>)
                                        } */}
                                        
                                        
                                    </div>
                                 </div>
                                <Form.Group className="mb-3" controlId="form">
                                    {/* {
                                        (!imgUrl && !postImage) &&
                                        <div className='outerbar'>
                                            <div className='innerbar' style={{ width: `${progresspercent}%` }}>{progresspercent}%</div>
                                        </div>
                                    } */}
                                    {/* <Form.Label className=' xch-label '>Upload photo</Form.Label> */}
                                    <div className="file-upload-wrapper-profile">
                                    <input type='file' />
                                    </div>
                                    <Form.Label><span className="xch-sm-txt">(Max-Size: 2MB)</span> </Form.Label>
                                </Form.Group>

                                <Button variant="primary" type="submit">Save</Button>
                        </Form>
                        
                        {/* {
                            imgUrl &&
                            <img src={imgUrl} alt='uploaded file' height={200} />
                        } */}
                    </div>
                    </Col>
                </Row>    
            </Container>
            </>
        )


    

}

export default EditProfile;