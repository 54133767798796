import {React, Component, useState, useMemo} from 'react';
import ReactDOM from 'react-dom/client';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';


const FacebookDataDeletion = () => {

        return (
            <>
            <div className="xch-main-header">
                <h2 className="text-center ">Facebook Data Deletion</h2>
            </div>
            <Container>
                <Row>
                    <Col>
                    <div>
                        <p>
                        Carnival-Xchange is just a listing of user carnival related item and we do not save your personal data in our server. 
                        But according to the Facebook Platform rules, we have to provide User Data Deletion Callback URL or Data Deletion Instructions URL. 
                        If you want to delete your activities for Carnival-Xchange App, you can remove your activities by the following instructions.
                        </p>

                        <p>
                        Go to Your Facebook Account’s Setting & Privacy. Click " Setting ".
                        Then, go to " Apps and Websites " and you will see all of your Apps activities.
                        Select the option box of Carnival-Xchange.
                        Click " Remove " button.
                        Congratulation, you are successfully remove your activities
                        </p>


                    </div>
                    </Col>
                </Row>    
            </Container>
            </>
        )
}

export default FacebookDataDeletion;