import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import {Component, useState, useMemo} from 'react';
import { useEffect, useContext, use } from 'react';
import { auth } from "../services/Firebase";





function Logout(){ 
const navigate = useNavigate();


useEffect(() => {
    auth.signOut().then(()=>{
        localStorage.removeItem("displayName")
		localStorage.removeItem("displayPhoto")
        navigate("/posts");
    })
}, []);


return (
<></>
);
}

export default Logout;
