import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// import {initializeFirestore} from 'firebase/firestore';

import { GoogleAuthProvider, signInWithPopup, getAuth } from "firebase/auth";


const firebaseConfig = {
	apiKey: "AIzaSyAExG2db_V1HCoiQVGchivlGgaMs7ZGyj4",
	authDomain: "carnival-xchange.firebaseapp.com",
	projectId: "carnival-xchange",
	storageBucket: "carnival-xchange.appspot.com",
	messagingSenderId: "441719603042",
	appId: "1:441719603042:web:154cd96ce159e37f48f63b",
	measurementId: "G-R69DC6ZDLB"
  };

  




const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);



export { db, auth, storage };


// async function loginWithGoogle() {
//     try {
//         const provider = new GoogleAuthProvider();
//         const auth = getAuth();

//         const { user } = await signInWithPopup(auth, provider);

//         return { uid: user.uid, displayName: user.displayName };
//     } catch (error) {
//         if (error.code !== 'auth/cancelled-popup-request') {
//             console.error(error);
//         }

//         return null;
//     }
// }

// export { loginWithGoogle };
