import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { MButton, Divider, IconButton } from "@mui/material";
import Button from 'react-bootstrap/Button';
import SendIcon from "@mui/icons-material/Send";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { db, auth, storage } from "../services/Firebase";
import Card from 'react-bootstrap/Card';
import TimeAgo from 'react-timeago'
import { Plus } from 'react-bootstrap-icons';
import { ArrowLeft } from 'react-bootstrap-icons';
import { Share } from 'react-bootstrap-icons';
import {
    collection,
    addDoc,
    collectionGroup,
    getDoc,
    getDocs,
    doc,
    updateDoc,
    onSnapshot,
    serverTimestamp,
    orderBy,
    where,
    query
} from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Navigate } from "react-router-dom";


const IndividualPost = () => { 
	const [isSignedIn, setSignedIn] = useState(false)
	const [post, setPosts] = useState([]);
	const [recieverData, setRecieverData] = useState(null);
	const [chatMessage, setChatMessage] = useState("");
	const [allMessages, setAllMessages] = useState([]);
	const user = auth.currentUser;
	const navigate = useNavigate();
    const [postId, setPostId] = useState("")
	const displayPhoto = localStorage.getItem("displayPhoto") ? localStorage.getItem("displayPhoto") : ""
	const displayName = localStorage.getItem("displayName") ? localStorage.getItem("displayName") : ""
	const [postTimeAgo, setPostTimeAgo] = useState("")
    const [userid, setUserId] = useState("")
    const [username, setUserName] = useState("")
    const [userPhoto, setUserPhoto] = useState("")
    const [postImage, setPostImage] = useState("")
    const [selectedCategory, setSelectedCategory] = useState("")
    const [newPostText, setNewPostText] = useState("")
    const [isPublished, setPublished] = useState(false)
    const [docData, setDocData] = useState({})
    
    useEffect(() =>{ 
            async function fetchData(){
                // console.log("user id: " + uid)
                const windowPath = window.location.pathname ? window.location.pathname : ""
                const sliced_path = windowPath.slice(1)
                const pathArray = sliced_path.split("/");
                let user_id = pathArray[1] ? pathArray[1] : "";
                let post_id = pathArray[2] ? pathArray[2] : "";
                // console.log(post_id, user_id) 
                setPostId(post_id)
                
                const docRef = doc(db, "/users/"+user_id+"/posts/"+post_id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    console.log("Document data:", docSnap.data());
                    setDocData(docSnap.data())
                  } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                  }
            }
            fetchData();   
       
    }, [postId]);


	




return (
	<>
		        <div className="xch-main-header xch-container ">
                    <div className="xch-header-flex">
                        <div className="xch-header-a"><Button variant="link" href={`/posts`}><ArrowLeft />&nbsp;Edit</Button></div>
                        <h2 className="text-center xch-header-c">Post</h2>
                        <div className="xch-header-b"></div>
                    </div>
                </div>
		

			
				<Container >
					<Row className="xch-container-inner-sm">
						<Col>
						{/* post area */}
                                <>
                                    { post ? 
                                        <>
                                            <Card className="xch-post-card">
                                                {/* <div className="xch-post-head">
                                                    <div className="xch-post-head-left">
                                                        <img src={userPhoto != "" ? userPhoto : displayPhoto}/></div>
                                                    <div className="xch-post-head-right">
                                                        <div className="xch-post-head-name">{username != "" ? username : displayName }</div>
                                                        <div className="xch-post-head-time"><TimeAgo date={postTimeAgo} /></div>
                                                    </div>
                                                </div> */}
                                                
                                                <div className="xch-post-image-container">
                                                    <Card.Img className="xch-post-image" variant="top" src={docData.image ? docData.image : ""}/>
                                                </div>
                                                <Card.Body key={0}>
                                                    <Card.Title></Card.Title>
                                                    <Card.Text className="xch-description">
                                                    {docData.description ? docData.description : ""} - <span className="xch-post-category">{docData.category ? docData.category : ""}</span>
                                                    </Card.Text>
                                                    <div className="xch-share-section">
                                                        <Button className="xch-btn-link" variant="link">Message</Button>
                                                        <Button className="xch-btn-link" variant="link"><Share />&nbsp; Share</Button>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                          
                                        </>
                                    : (<></>)
                                    }
                                </>							
						</Col>
							
						</Row>
				</Container>

                <Container>
                    <div className="xch-disclaimer">
                    <strong>DISCLAIMER:</strong> Transactions on this site are strictly between the buyer and the seller. Neither ticketfederation.com nor its owners accept any liability for moneys lost, items damaged, stolen, or any other claim relating to transactions between buyer and seller. You "the user", use this site, and arrange exchanges at your own risk.
                    </div>
                </Container>
	</>
)
};

export default IndividualPost;

const root = {
display: "flex",
flexDirection: "row",
flex: 1,
width: "100%",
};

const left = {
display: "flex",
flex: 0.2,
height: "95vh",
margin: 10,
flexDirection: "column",
};

const right = {
display: "flex",
flex: 0.8,
height: "95vh",
margin: 10,
flexDirection: "column",
};

const input = {
flex: 1,
outline: "none",
borderRadius: 5,
border: "none",
};

const messagesDiv = {
backgroundColor: "#FBEEE6",
padding: 5,
display: "flex",
flexDirection: "column",
flex: 1,
maxHeight: 460,
overflowY: "scroll",
};
