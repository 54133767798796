import React from 'react';
import TfLogo from "../tf_logo.png"
import { Link } from 'react-router-dom';
import '../App.css';
import Container from 'react-bootstrap/Container';


const Footer = () => {
    const d = new Date();
    let year = d.getFullYear();
    return(
        <Container className="xch-container">
            <div className="footer text-center">
            &#169; {year} CarnivalXchange.com   <br/><a href="/privacy"> Privacy Policy</a>   &nbsp; | &nbsp; <a href="/facebook-data-deletion"> Facebook Data Deletion</a>
            </div>
        </Container>
    )
}
export default Footer