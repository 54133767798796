import {React, Component, useState, useMemo} from 'react';
import ReactDOM from 'react-dom/client';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';

const Faq = () => {
        return (
            <>
            <div className="xch-container xch-main-header">
                <div className="xch-header-flex">
                        <div className="xch-header-a"></div>
                        <h2 className="text-center xch-header-c">FAQs</h2>
                        <div className="xch-header-b"></div>
                    </div>
            </div>
            
            <Container className="xch-container xch-container-medium">
                <Row>
                    <Col>
                    <div>
                        <p>
                            Coming Soon!
                        </p>
                    </div>
                    </Col>
                </Row>    
            </Container>
            </>
        )
}


export default Faq;