import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Navi from "./Components/Navigation";
import Footer from "./Components/Footer";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Navigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import 'bootstrap/dist/css/bootstrap.min.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


import reportWebVitals from './reportWebVitals';
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/css/bootstrap.min.css"
  integrity="sha384-Zenh87qX5JnK2Jl0vWa8Ck2rdkQ2Bzep5IDxbcnCeuOxjzrPF/et3URy9Bv1WTRi"
  crossorigin="anonymous"
/>

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Container fluid className="xch-section-top">
      <Row className="xch-header">
        <Col className="xch-nav"><Navi /></Col>
      </Row>
    </Container>

    <Container fluid className="xch-section-mid">
      <Row className="xch-main">
        <Col className="xch-mid-col"><App /></Col>
      </Row>
    </Container>

    <Container fluid className="xch-section-bot">
      <Row className="xch-footer">
        <Col><Footer /></Col>
      </Row>
    </Container>

  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
