
import { BrowserRouter, Router,Routes, Route, Link } from 'react-router-dom';
import SignIn from "./Screens/Signin";
import SignUp from "./Screens/Signup";
import Messages from "./Screens/Messages";
import About from "./Screens/About";
import Contact from "./Screens/Contact";
import Posts from "./Screens/Post";
import NewPostResponseMessage from "./Screens/NewPostResponseMessage";
import IndividualPost from "./Screens/IndividualPost"; 
import MyPosts from "./Screens/MyPosts";
import UserProfile from "./Screens/UserProfile";
import NewPost from "./Screens/NewPost";
import PreviewPost from "./Screens/PreviewPost";
import EditPost from "./Screens/EditPost";
import Logout from "./Screens/Logout";
import Privacy from "./Screens/Privacy";
import Faqs from "./Screens/Faq";
import FacebookDataDeletion from "./Screens/FacebookDataDeletion";
import "./App.css";
import { Helmet, HelmetProvider } from "react-helmet-async";


function App() {
	

	return (
		<HelmetProvider>
			<Helmet>
				<title>Carnival Xchange</title>
				<meta
					name="description"
					content="Sell your costumes, tickets, accommodation, packages, transportation"
				/>;
				<meta property="og:type" content="website" />
				<meta property="og:url" content="www.carnivalxchange.com" />
				<meta
				property="og:title"
				content="Sell your costumes, tickets, accommodation, packages, transportation"
				/>
				<meta
				property="og:description"
				content="Sell your costumes, tickets, accommodation, packages, transportation"
				/>
				<meta
				property="og:image"
				content="https://www.carnivalxchange.com/image.jpg"
				/>
			</Helmet>
			<div className="App">
				<BrowserRouter>
					<Routes>
						{/* <Route  path='/' element={< About />}></Route> */}
						<Route  path='/' element={< Posts />}></Route>
						<Route  path='/signup' element={< SignUp />}></Route>
						<Route  path='/about' element={< About />}></Route>
						<Route  path='/logout' element={< Logout />}></Route>
						<Route  path='/posts' element={< Posts />}></Route>
						<Route  path='/post/new' element={< NewPost />}></Route>
						<Route  path='/faq' element={< Faqs />}></Route>
						<Route  exact path='/signin' element={< SignIn />}></Route>
						<Route  path='/messages/:recieverId' element={< Messages />}></Route>
						<Route  path='/post/message/new' element={< NewPostResponseMessage />}></Route>
						<Route  path='/posts/:userId/:postId' element={< IndividualPost />}></Route>
						<Route  path="/privacy" element={<Privacy />} />
						<Route  path='/user/profile' element={< UserProfile />}></Route>
						<Route  path='/user/profile/edit' element={< UserProfile />}></Route>
						<Route  exact path='/post/:postId' element={< PreviewPost />}></Route>
						<Route  exact path='/myposts' element={< MyPosts />}></Route>
						<Route  exact path='/post/:postId/edit' element={< EditPost />}></Route>
						<Route  path='/contact' element={< Contact />}></Route>
						<Route  path="/facebook-data-deletion" element={<FacebookDataDeletion />} />
					</Routes>
				</BrowserRouter>
			</div>
		</HelmetProvider>
	
	);
}

export default App;
