import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { MButton, Divider, IconButton } from "@mui/material";
import Button from 'react-bootstrap/Button';
import SendIcon from "@mui/icons-material/Send";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { db, auth, storage } from "../services/Firebase";
import Card from 'react-bootstrap/Card';
import TimeAgo from 'react-timeago'
import { Plus } from 'react-bootstrap-icons';
import { ArrowLeft } from 'react-bootstrap-icons';
import { Share } from 'react-bootstrap-icons';
import {
    collection,
    addDoc,
    getDoc,
    doc,
    updateDoc,
    onSnapshot,
    serverTimestamp,
    orderBy,
    query
} from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Navigate } from "react-router-dom";


function UsersComponent(props) {
	const handleToggle = (username, userId) => {
		props.setRecieverData({
		username: username,
		userId: userId,
		});
		props.navigate(`/messages/${userId}`);
		console.log(userId)
	};

return (
	<List
	dense
	sx={{ width: "100%", maxWidth: 360,
			bgcolor: "background.paper" }}
	>
	{props.posts?.map((value, index) => {
		const labelId = `checkbox-list-secondary-label-${value}`;

		if (props.currentUserId !== value.userId)
		return (
			<ListItem key={value.userId} disablePadding>
			<ListItemButton
				onClick={() => {
				handleToggle(value.username, value.userId);
				}}
			>
				<ListItemAvatar>
				<Avatar
					alt={`${value.username}`}
					src={`${value.username}.jpg`}
				/>
				</ListItemAvatar>
				<ListItemText id={labelId}
						primary={`${value.username}`} />
			</ListItemButton>
			</ListItem>
		);
	})}
	</List>
);
}










const PreviewPost = () => { 
	const [isSignedIn, setSignedIn] = useState(false)
	const [post, setPosts] = useState([]);
	const [recieverData, setRecieverData] = useState(null);
	const [chatMessage, setChatMessage] = useState("");
	const [allMessages, setAllMessages] = useState([]);
	const user = auth.currentUser;
	const navigate = useNavigate();
    const [postId, setPostId] = useState("")
	const displayPhoto = localStorage.getItem("displayPhoto") ? localStorage.getItem("displayPhoto") : ""
	const displayName = localStorage.getItem("displayName") ? localStorage.getItem("displayName") : ""
	const [postTimeAgo, setPostTimeAgo] = useState("")
    const [userid, setUserId] = useState("")
    const [username, setUserName] = useState("")
    const [userPhoto, setUserPhoto] = useState("")
    const [postImage, setPostImage] = useState("")
    const [selectedCategory, setSelectedCategory] = useState("")
    const [newPostText, setNewPostText] = useState("")
    const [isPublished, setPublished] = useState(false)
    

    const publishPost = async (postid, userid) => {
        if(!postid || !userid){return}
        //set firebase post status to active
        const docRef = doc(db, "users", userid, "posts", postid);
        const data = {active: true}
        try {
                updateDoc(docRef, data)
                .then(docRef => {
                    console.log("Published")
                    setPublished(true)
                    //navigatt to preview page
                    // navigate(`/post/${postid}`) 
                })
                .catch(error => {
                    console.log(error);
                }) 
        }
        catch(error){
            console.log(error)
        }
    }
    
    useEffect(() =>{ 

        onAuthStateChanged(auth, async (user) => {
        if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            const uid = user.uid;
            setSignedIn(true);
            setUserId(user.uid ? user.uid : "")
            

            const docRef = doc(db, "users", uid);
            try {
                const docSnap = await getDoc(docRef);
                if(docSnap.exists()) {
                    console.log(docSnap.data());
                    setUserName(docSnap.data().displayName ? docSnap.data().displayName : "")
                    setUserPhoto(docSnap.data().photoURL ? docSnap.data().photoURL : "")
                } else {
                    console.log("Document does not exist")
                }
            } catch(error) {
                console.log(error)
            }
            // console.log("User is signed in " + user.uid)
            // ...
            
            async function fetchData(){
                // console.log("user id: " + uid)
                const windowPath = window.location.pathname ? window.location.pathname : ""
                const sliced_path = windowPath.slice(1)
                const pathArray = sliced_path.split("/");
                let post_id = pathArray[1] ? pathArray[1] : "";
                setPostId(post_id)
                // console.log("post id: " + post_id)

                //post reference
                // const post_collection = (db, "users", uid, "posts", post_id);
                const docRef = doc(db, "users", uid, "posts", post_id);
                // Create a query against the collection.
                const docSnap = await getDoc(docRef);
                // console.log(docSnap)
                try {
                    const docSnap = await getDoc(docRef);
                    if(docSnap.exists()) {
                        console.log(docSnap.data());
                        setPosts(docSnap.data());
                        var post_time = new Date(docSnap.data().createdAt.toDate())
                        console.log(post_time)
                        setPostTimeAgo(post_time);
                        setPostImage(docSnap.data().image ? docSnap.data().image : "")
                        setSelectedCategory(docSnap.data().category ? docSnap.data().category : "")
                        setNewPostText(docSnap.data().description ? docSnap.data().description : "")
                        setPublished(docSnap.data().active ? docSnap.data().active : "")
                    } else {
                        console.log("Document does not exist")
                    }
                
                } catch(error) {
                    console.log(error)
                }
            }
            fetchData();   
        } else {
            // User is signed out
            setSignedIn(false);
            //console.log("User not signed in")
            navigate("/signin");
            // ...
        }
        });
    }, [postId]);


	




return (
	<>
		        <div className="xch-main-header xch-container ">
                    <div className="xch-header-flex">
                        <div className="xch-header-a"><Button variant="link" href={`/post/${postId}/edit`}><ArrowLeft />&nbsp;Edit</Button></div>
                        <h2 className="text-center xch-header-c">Preview</h2>
                        <div className="xch-header-b"></div>
                    </div>
                </div>
		

			
				<Container >
					<Row className="xch-container-inner-sm">
						<Col>
						{/* post area */}
                                <>
                                    { post ? 
                                        <>
                                            <Card className="xch-post-card">
                                                <div className="xch-post-head">
                                                    <div className="xch-post-head-left">
                                                        <img src={userPhoto != "" ? userPhoto : displayPhoto}/></div>
                                                    <div className="xch-post-head-right">
                                                        <div className="xch-post-head-name">{username != "" ? username : username }</div>
                                                        <div className="xch-post-head-time"><TimeAgo date={postTimeAgo} /></div>
                                                    </div>
                                                </div>
                                                
                                                <div className="xch-post-image-container">
                                                    <Card.Img className="xch-post-image" variant="top" src={postImage ? postImage : ""}/>
                                                </div>
                                                <Card.Body key={0}>
                                                    <Card.Title></Card.Title>
                                                    <Card.Text className="xch-description">
                                                    {post.description} - <span className="xch-post-category">{post.category}</span>
                                                    </Card.Text>
                                                    <div className="xch-share-section">
                                                        <Button className="xch-btn-link" variant="link">Message</Button>
                                                        <Button className="xch-btn-link" variant="link"><Share />&nbsp; Share</Button>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                            <div className="xch-post-footer">
                                                <div className="xch-post-footer-left">
                                                    <Button className="xch-btn-edit" href={`/post/${postId}/edit`} variant="link"><ArrowLeft />&nbsp;Edit</Button>
                                                </div> 
                                                <div className="xch-post-footer-right ">
                                                <Button 
                                                    disabled={isPublished}
                                                    onClick={() => {publishPost(postId, userid);
                                                    }} variant="primary">
                                                        {isPublished ? ("Published") : ("Publish Now")}
                                                </Button>
                                                </div>
                                            </div>
                                        </>
                                    : (<></>)
                                    }
                                </>							
						</Col>
							
						</Row>
				</Container>

                <Container>
                    <div className="xch-disclaimer">
                    <strong>DISCLAIMER:</strong> Transactions on this site are strictly between the buyer and the seller. Neither ticketfederation.com nor its owners accept any liability for moneys lost, items damaged, stolen, or any other claim relating to transactions between buyer and seller. You "the user", use this site, and arrange exchanges at your own risk.
                    </div>
                </Container>
	</>
)
};

export default PreviewPost;

const root = {
display: "flex",
flexDirection: "row",
flex: 1,
width: "100%",
};

const left = {
display: "flex",
flex: 0.2,
height: "95vh",
margin: 10,
flexDirection: "column",
};

const right = {
display: "flex",
flex: 0.8,
height: "95vh",
margin: 10,
flexDirection: "column",
};

const input = {
flex: 1,
outline: "none",
borderRadius: 5,
border: "none",
};

const messagesDiv = {
backgroundColor: "#FBEEE6",
padding: 5,
display: "flex",
flexDirection: "column",
flex: 1,
maxHeight: 460,
overflowY: "scroll",
};
