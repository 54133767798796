import {React, Component, useState, useMemo} from 'react';
import ReactDOM from 'react-dom/client';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Reaptcha from 'reaptcha';
import { db, auth } from "../services/Firebase";
import {
addDoc,
collection,
onSnapshot,
orderBy,
query,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Navigate } from "react-router-dom";


const Contact = () => {
    const [captchaToken, setCaptchaToken] = useState(null);
    const captchaRef = useRef(null)
    const location = useLocation()
	console.log(location.state != null ? location.state : "location not set")
	// const un = process.env.ADMIN_USER_NAME != "" ? process.env.ADMIN_USER_NAME : ""
    // const ui = process.env.ADMIN_USER_ID != "" ? process.env.ADMIN_USER_ID : ""
    const un = process.env.REACT_APP_ADMIN_USER_NAME != "" ? process.env.REACT_APP_ADMIN_USER_NAME : ""
    const ui = process.env.REACT_APP_ADMIN_USER_ID != "" ? process.env.REACT_APP_ADMIN_USER_ID : ""
	const [isSignedIn, setSignedIn] = useState(false)
	const [users, setUsers] = useState([]);
	const [recieverData, setRecieverData] = useState( 
											(ui !='"' && un!="") ?
												{
													username: un, 
													userId: ui,
												}
											: null
											);
	const [chatMessage, setChatMessage] = useState("");
	const [allMessages, setAllMessages] = useState([]);
	const [userid, setUserId] = useState("")
    const [username, setUserName] = useState("")
    const [userPhoto, setUserPhoto] = useState("")
	const user = auth.currentUser;
	const navigate = useNavigate();
	var displayPhoto = ""
	var displayName = ""
	displayPhoto = localStorage.getItem("displayPhoto") ? localStorage.getItem("displayPhoto") : ""
	displayName = localStorage.getItem("displayName") ? localStorage.getItem("displayName") : ""
	useEffect(() => {
		const unsub = onSnapshot(collection(db, "users"), (snapshot) => {
		setUsers(snapshot.docs.map((doc) => doc.data()));
		});
		return unsub;
	}, []);


	useEffect(() => {
		onAuthStateChanged(auth, (user) => {    
            console.log(process.env.ADMIN_USER_NAME)
			if (user) {
                const un = process.env.REACT_APP_ADMIN_USER_NAME != "" ? process.env.REACT_APP_ADMIN_USER_NAME : ""
                 const ui = process.env.REACT_APP_ADMIN_USER_ID != "" ? process.env.REACT_APP_ADMIN_USER_ID : ""
				if(location.state != null)
				{
					setRecieverData({userId:ui, username: un})
				}
				const uid = user.uid;
				setSignedIn(true);
				setUserId(user.uid ? user.uid : "")
				setUserName(user.displayName ? user.displayName : "")
				setUserPhoto(user.photoURL ? user.photoURL : "")
				// console.log("User is signed in " + user.uid)				
				async function fetchData(){
					try {
						if (recieverData) {
							console.log(recieverData)
							const unsub = onSnapshot(
								query(
								collection(
									db,
									"users",
									user?.uid,
									"chatUsers",
									recieverData?.userId,
									"messages"
								),
							orderBy("timestamp")
							),
							(snapshot) => {
								setAllMessages(
									snapshot.docs.map((doc) => ({
									id: doc.id,
									messages: doc.data(),
									}))
								);
							}
						);
						return unsub;
						}
					} catch(error) {
						console.log(error)
					}
				}
				fetchData();   
			} else {
				// User is signed out
				setSignedIn(false);
				//console.log("User not signed in")
				navigate("/signin");
			}
		});	
	}, [recieverData?.userId]);

    // const handleSubmit = (e) =>{
    //     e.preventDefault();
    //     const token = captchaRef.current.getValue();
    //     captchaRef.current.reset();
    // }
    const verify = () =>{
        captchaRef.current.getResponse().then(res => {
            setCaptchaToken(res)
        })
    }

    const sendMessage = async (e) => {
        e.preventDefault();
        // const token = captchaRef.current.getValue();
        // captchaRef.current.reset();
	    if(chatMessage=="") return	//if message is empty do not send mesage
	    try {
            if (user && recieverData) {
                console.log(`USER : ${user.uid} `)
                console.log(`RECEIVER : ${recieverData.userId}` )
                await addDoc(
                collection(
                    db,
                    "users",
                    user.uid,
                    "chatUsers",
                    recieverData.userId,
                    "messages"
                ),
                {
                    username: user.displayName,
                    messageUserId: user.uid,
                    message: chatMessage,
                    timestamp: new Date(),
                }
                );

                await addDoc(
                collection(
                    db,
                    "users",
                    recieverData.userId,
                    "chatUsers",
                    user.uid,
                    "messages"
                ),
                {
                    username: user.displayName,
                    messageUserId: user.uid,
                    message: chatMessage,
                    timestamp: new Date(),
                }
                );
		}
        } catch (error) {
            console.log(error);
        }
	    setChatMessage("");
    }
    return (
        <>
        <div className="xch-container xch-main-header">
                <div className="xch-header-flex">
                        <div className="xch-header-a"></div>
                        <h2 className="text-center xch-header-c">CONTACT</h2>
                        <div className="xch-header-b"></div>
                    </div>
            </div>
        
        <Container>
            <Form onSubmit={sendMessage}>
                <Row className="xch-contact">
                {/* <Col>
                <div> */}
                        <Form.Group  className="mb-3" controlId="formBasicEmail">
                            <div className="xch-form-header">Send us a message.</div>
                        </Form.Group>
                    
                    
                        <Form.Group  as={Col}  className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="" />
                            {/* <Form.Text className="text-muted text-center">
                            We'll never share your email with anyone else.
                            </Form.Text> */}
                        </Form.Group>

                        <Form.Group  as={Col}  className="mb-3" controlId="formBasicPhone">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control type="phone" placeholder="" />
                            {/* <Form.Text className="text-muted text-center">
                            Phone Number
                            </Form.Text> */}
                        </Form.Group>
                {/* </div>
                </Col> */}
            </Row>   

                <Row className="xch-contact">
                <Col>
                        <Form.Group className="mb-3" controlId="formBasicTextArea">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="enter message" 
                            onChange={(e) => setChatMessage(e.target.value)}
                            />
                        </Form.Group>
                        
                        <Form.Group className="mb-3" controlId="formBasicRecaptcha">
                            {/* <Reaptcha 
                            sitekey={process.env.REACT_APP_SITE_KEY}
                            ref={captchaRef}
                            onVerify={verify} 
                            theme="light"
                            classname="mb-3"
                            >
                            </Reaptcha> */}
                        </Form.Group>
        
                        <Button variant="primary" type="submit">
                            Send Message
                        </Button>
                </Col>
            </Row>  
            </Form>  
        </Container>
        </>
    )


    

}

export default Contact;