import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { Divider, IconButton } from "@mui/material";
import Button from 'react-bootstrap/Button';

import SendIcon from "@mui/icons-material/Send";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import MAvatar from "@mui/material/Avatar";
import { useNavigate } from "react-router";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { db, auth, storage } from "../services/Firebase";
import Card from 'react-bootstrap/Card';
import TimeAgo from 'react-timeago'
import { Plus } from 'react-bootstrap-icons';
import { Filter } from 'react-bootstrap-icons';
import { Share } from 'react-bootstrap-icons';
import Avatar from 'react-avatar';
import InfiniteScroll from 'react-infinite-scroll-component'
import ModalImage from "react-modal-image";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import Ximg from "../img.png"
import 'photoswipe/dist/photoswipe.css'


import {
addDoc,
collection,
doc,
collectionGroup,
getDocs,
where,
onSnapshot,
orderBy,
query,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Navigate } from "react-router-dom";
import LazyLoad from 'react-lazy-load';


function UsersComponent(props) {
	const handleToggle = (username, userId) => {
		props.setRecieverData({
		username: username,
		userId: userId,
		});
		props.navigate(`/messages/${userId}`);
		console.log(userId)
	};

return (
	<List
	dense
	sx={{ width: "100%", maxWidth: 360,
			bgcolor: "background.paper" }}
	>
	{props.posts?.map((value, index) => {
		const labelId = `checkbox-list-secondary-label-${value}`;

		if (props.currentUserId !== value.userId)
		return (
			<ListItem key={value.userId} disablePadding>
			<ListItemButton
				onClick={() => {
				handleToggle(value.username, value.userId);
				}}
			>
				<ListItemAvatar>
				<Avatar
					alt={`${value.username}`}
					src={`${value.username}.jpg`}
				/>
				</ListItemAvatar>
				<ListItemText id={labelId}
						primary={`${value.username}`} />
			</ListItemButton>
			</ListItem>
		);
	})}
	</List>
);
}

let page = 1;
const fetchData = (setPosts, setFilteredResults, filteredResults, setItems, items, userId) => {
	const unsub = onSnapshot(query(collectionGroup(db, "posts"), where('userid', "==", userId)), (snapshot) => {
	// const unsub = onSnapshot(query(collectionGroup(db, "posts"), where('active', "==", true), orderBy("timestamp", "desc")), (snapshot) => {
	let postDocs = snapshot.docs.map((doc) => ({
		...doc.data(),
		id: doc.id,
		}));
		setPosts(postDocs);
		
		if(filteredResults.length==0 ){
			console.log("Posts")
			console.log(filteredResults.length)
			setFilteredResults(postDocs) 
		}
		else{
			console.log("Filtered")
			console.log(filteredResults)
			setFilteredResults(filteredResults)
		}
		
		setItems(  [...items,   postDocs  ]);
		//console.log(postDocs)
		page = page + 1;       
	});
}

const MyPosts = () => { 
	const [isSignedIn, setSignedIn] = useState(false)
	const [posts, setPosts] = useState([]);
	const [recieverData, setRecieverData] = useState(null);
	const [chatMessage, setChatMessage] = useState("");
	const [allMessages, setAllMessages] = useState([]);
	const user = auth.currentUser;
	const [users, setUsers] = useState([]);
	const navigate = useNavigate();
	const [username, setUserName] = useState("")
    const [userPhoto, setUserPhoto] = useState("")
	const [userEmail, setUserEmail] = useState("")
	const [filteredResults, setFilteredResults] = useState([]);
	const [items, setItems] = useState([]);
	const [loggedInUserId, setUserId] = useState("")
	const [canShareMobile, setCanShareMobile] = useState(false)

	const refresh = (setItems) => {};

	function openUserMessages(uid, username, msgRef){
		//console.log("user id:", uid, ", username", username, ", msgref:" , msgRef )
		navigate("/post/message/new", {
			state: {
				userId: uid,
				username: username,
				mesageReference: msgRef
			}
		});
	}

	function sharePost(title, desc, url, uid, pid){
		// console.log(title, desc, url)
		const user_id = uid
		const post_id = pid
		// const base_url = "https://www.carnival-xchange.firebaseapp.com/"
		// const base_url = "http://localhost:3000/getposts?i="+user_id+"&p="+post_id
		if (navigator.share) {
			navigator.share({
				title: title,
				text: desc,
				url: url,
			  })
				.then(() => console.log('Successful share'))
				.catch((error) => console.log('Error sharing', error));
		} else {
			const base_url = "https://carnivalxchange.com/getposts"
			const share_this = encodeURIComponent(`${base_url}/${post_id}/${user_id}`)
			const share_url = "https://www.facebook.com/sharer/sharer.php?u=" + share_this

			// https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.fineahban.com%2Fposts%2F148880
			// https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fcarnival-xchange.firebaseapp.com%2FCzo3LMUFaQYQC9XOyVf5%2FRKXBIKPxIkb1Y9PSkJCBjIpdb9h1
			console.log("share url", share_url)
		}
	}

	useEffect( () => {
		onAuthStateChanged(auth, (user) => {
			if (user) {
				const uid = user.uid;
				setSignedIn(true);
				setUserId(user.uid ? user.uid : "")
				setUserName(user.displayName ? user.displayName : "")
				setUserPhoto(user.photoURL ? user.photoURL : "")
				// console.log("User is signed in " + user.uid)	
				fetchData(setPosts, setFilteredResults, filteredResults, setItems, items, uid)
			} else {
				setSignedIn(false);
				navigate("/signin");
			}
		});	
	}, [loggedInUserId]);

	// onAuthStateChanged(auth, (user) => {
	// if (user) {
	// 	const uid = user.uid;
	// 	setSignedIn(true);
	// 	setUserName(user.displayName);
	// 	console.log("User is signed in")
	// 	// ...
	// } else {
	// 	// User is signed out
	// 	setSignedIn(false);
	// 	console.log("User not signed in")
	// 	navigate("/signin");
	// 	// ...
	// }
	// });


return (
	<>
		        <div className="xch-main-header">
                    <div className="xch-header-flex">
                        <div className="xch-header-a">
                            {/* <Button variant="link" href="/posts/filter"><Filter/> Filter</Button> */}
                        </div>
                        <h2 className="text-center xch-header-c">My Listings</h2>
                        <div className="xch-header-b"><Button className="btn btn-primary" variant="link" href="/post/new"><Plus/> New</Button></div>
                    </div>
                </div>
		

				<Container >
					<Row className="xch-container-inner-sm">
						<Col>
						{/* posts area */}
						<InfiniteScroll
								dataLength={filteredResults.length}
								next={() => {
                                    fetchData(setPosts, setFilteredResults, filteredResults, setItems, items, loggedInUserId);
                                  }}
                                hasMore={true}

								// loader={<h4>Loading...</h4>}
								// endMessage={
								// <p style={{ textAlign: 'center' }}>
								// 	<b>You've reached the end.</b>
								// </p>
								// }

                                // below props only if you need pull down functionality
                                refreshFunction={refresh}
                                pullDownToRefresh
                                pullDownToRefreshThreshold={50}
                                pullDownToRefreshContent={
                                <h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>
                                }
                                releaseToRefreshContent={
                                <h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>
                                }
							>
								{posts &&
									filteredResults.map(({ id, description, image, userid, userPhoto, category, createdAt, photoUrl, displayName, username }) => {
										const base_url = "https://carnivalxchange.com/getposts"
										const share_this = encodeURIComponent(`${base_url}/${id}/${userid}`)
										const share_url = "https://www.facebook.com/sharer/sharer.php?u=" + share_this
									return (
										<div  key={id} >
											<Card className="xch-post-card" key={id}>
												<div key={0}  className="xch-post-head">
													<div  key={0} className="xch-post-head-left">
														{  userPhoto ? ( <img src={userPhoto ? userPhoto : ""}/> ) : (<Avatar name={username} size="50" />)}
													</div>
													<div  key={1} className="xch-post-head-right">
														<div  key={0} className="xch-post-head-name">{username}</div>
														<div  key={1} className="xch-post-head-time">
															<TimeAgo 
															key={0} 
															date={
																new Date(createdAt.toDate())
															} />
														</div>
													</div>
												</div>
												
												<div key={1}  className="xch-post-image-container">
													{ image ?  (
														<>

														
													
														<div className="xch-bg-image" style={{backgroundImage:"url("+image+")", 
															backgroundSize:'container',
															backgroundRepeat:'no-repeat',

															}}>
															<ModalImage
																small={Ximg}
																classsName="xch-img-blank"
																large={image}
																key={0} 
															/> 
														</div>
														
														</>

												
													) : (<></>) }
												</div>
												<Card.Body  key={2} >
													<Card.Text  key={0}  className="xch-description">
													{description}
													</Card.Text>
													<div  key={1} className="xch-share-section">
														<Button className="xch-btn-share btn-share" variant="primary"><a href={"/post/" + id + "/edit"} >Edit</a></Button> 
														{
														canShareMobile ? (
														<Button  key={1} className="xch-btn-share btn-share" variant="link" onClick={() => { sharePost(
															"Carnival-Xchange", (description ? description : "-"), (image) ? image : "", userid, id)  }}><Share />&nbsp;Share</Button>
															):(<></>)
														}
															{/* &nbsp;<Button  key={2} target="_blank" rel="nofollow" className="xch-btn-share btn-fb" variant="link" href={share_url}><Facebook />&nbsp;Facebook</Button> */}
														
													</div>
												</Card.Body>
											</Card>
										</div>
									);
								})}
							</InfiniteScroll>

							{
								(posts.length < 1) ? (
									<p className="xch-no-posts"> Let's get you started!<br/><br/>
									<Button variant="primary" href="/post/new"><Plus/> Add New Post</Button></p>   
								) : (<></>) }
							
						</Col>
							
						</Row>
				</Container>

                <Container>
                    <div className="xch-disclaimer">
                    <strong>DISCLAIMER:</strong> Transactions on this site are strictly between the buyer and the seller. Neither ticketfederation.com nor its owners accept any liability for moneys lost, items damaged, stolen, or any other claim relating to transactions between buyer and seller. You "the user", use this site, and arrange exchanges at your own risk.
                    </div>
                </Container>
	</>
)
};

export default MyPosts;

const root = {
display: "flex",
flexDirection: "row",
flex: 1,
width: "100%",
};

const left = {
display: "flex",
flex: 0.2,
height: "95vh",
margin: 10,
flexDirection: "column",
};

const right = {
display: "flex",
flex: 0.8,
height: "95vh",
margin: 10,
flexDirection: "column",
};

const input = {
flex: 1,
outline: "none",
borderRadius: 5,
border: "none",
};

const messagesDiv = {
backgroundColor: "#FBEEE6",
padding: 5,
display: "flex",
flexDirection: "column",
flex: 1,
maxHeight: 460,
overflowY: "scroll",
};
