import React, { useEffect, useState } from "react";
import { Button, Divider, IconButton } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { useNavigate } from "react-router";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { db, auth, storage } from "../services/Firebase";
import Card from 'react-bootstrap/Card';
import TimeAgo from 'react-timeago'
import { Plus } from 'react-bootstrap-icons';
import { Filter } from 'react-bootstrap-icons';
import { Facebook } from 'react-bootstrap-icons';
import { Share } from 'react-bootstrap-icons';
import Avatar from 'react-avatar';
import ModalImage from "react-modal-image";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import Ximg from "../img.png"
import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item } from 'react-photoswipe-gallery'
import InfiniteScroll from 'react-infinite-scroll-component'


import {
addDoc,
collection,
doc,
collectionGroup,
getDocs,
where,
onSnapshot,
orderBy,
query,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Navigate, useLocation } from "react-router-dom";
import LazyLoad from 'react-lazy-load';




let page = 1;

const fetchData = (setPosts, setFilteredResults, filteredResults, setItems, items) => {
    const unsub = onSnapshot(query(collectionGroup(db, "posts"), where('active', "==", true)), (snapshot) => {
    // const unsub = onSnapshot(query(collectionGroup(db, "posts"), where('active', "==", true), orderBy("timestamp", "desc")), (snapshot) => {
    let postDocs = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        }));
        setPosts(postDocs);
        
        if(filteredResults.length==0 ){
            console.log("Posts")
            console.log(filteredResults.length)
            setFilteredResults(postDocs) 
        }
        else{
            console.log("Filtered")
            console.log(filteredResults)
            setFilteredResults(filteredResults)
        }
        
        setItems(  [...items,   postDocs  ]);
        //console.log(postDocs)
        page = page + 1;       
    });
}
 
const refresh = (setItems) => {};
//=================================================


function UsersComponent(props) {
	const handleToggle = (username, userId) => {
		props.setRecieverData({
		username: username,
		userId: userId,
		});
		props.navigate(`/messages/${userId}`);
		console.log(userId)
	};

return (
	<List
	dense
	sx={{ width: "100%", maxWidth: 360,
			bgcolor: "background.paper" }}
	>
	{props.posts?.map((value, index) => {
		const labelId = `checkbox-list-secondary-label-${value}`;

		if (props.currentUserId !== value.userId)
		return (
			<ListItem key={value.userId} disablePadding>
			<ListItemButton
				onClick={() => {
				handleToggle(value.username, value.userId);
				}}
			>
				<ListItemAvatar>
				<Avatar
					alt={`${value.username}`}
					src={`${value.username}.jpg`}
				/>
				</ListItemAvatar>
				<ListItemText id={labelId}
						primary={`${value.username}`} />
			</ListItemButton>
			</ListItem>
		);
	})}
	</List>
);
}

const Posts = () => { 
	const [isSignedIn, setSignedIn] = useState(false)
	const [lazyHeight, setLazyHeight] = useState(200);
	const [posts, setPosts] = useState([]);
	const [hiddenBlock, setHiddenBlock] = useState(true)
	const [recieverData, setRecieverData] = useState(null);
	const [chatMessage, setChatMessage] = useState("");
	const [allMessages, setAllMessages] = useState([]);
	const user = auth.currentUser;
	const navigate = useNavigate();
	const [username, setUserName] = useState("")
    const [userPhoto, setUserPhoto] = useState("")
	const [userEmail, setUserEmail] = useState("")
	const [filteredResults, setFilteredResults] = useState([]);
	const [searchInput, setSearchInput] = useState('');
	const [scrDimensions, setScrDimensions] = useState();
	const [imageHeight, setImageHeight] = useState("300");
	const [loggedInUserId, setUserId] = useState("")
	const [active, setActive] = useState(0);
	const location = useLocation()
	const [currentClass, setCurrentClass] = useState("");
    const [items, setItems] = useState([]);
	const [canShareMobile, setCanShareMobile] = useState(false)


    useEffect(()=>{
        fetchData(setPosts, setFilteredResults, filteredResults, setItems,items)
      },[])



	useEffect(() => {
		onAuthStateChanged(auth, (user) => {    
			if (user) {
                if(location.state != null)
				{
					setRecieverData({userId:location.state.userId, username: location.state.username})
				}
				const uid = user.uid;
				setSignedIn(true);
				setUserId(user.uid ? user.uid : "")
				setUserName(user.displayName ? user.displayName : "")
				setUserPhoto(user.photoURL ? user.photoURL : "")				  
			} else {
				setSignedIn(false);
			}
		});	
	}, []);






	
	useEffect( () => {
		function getWindowSize() {
			const {innerWidth, innerHeight} = window;
			setImageHeight( (innerWidth > 400) ? 300 : 300)
			setLazyHeight( (innerWidth > 768) ? 200 : 100 )
			setScrDimensions(innerWidth, innerHeight)
		  }
		  getWindowSize()
          fetchData(setPosts, setFilteredResults, filteredResults, setItems,items)

		  if (navigator.share) setCanShareMobile(true)
	}, []);
 

	function sharePost(title, desc, url, uid, pid){
		// console.log(title, desc, url)
		const user_id = uid
		const post_id = pid
		// const base_url = "https://www.carnival-xchange.firebaseapp.com/"
		// const base_url = "http://localhost:3000/getposts?i="+user_id+"&p="+post_id
		if (navigator.share) {
			navigator.share({
				title: title,
				text: desc,
				url: url,
			  })
				.then(() => console.log('Successful share'))
				.catch((error) => console.log('Error sharing', error));
		} else {
			const base_url = "https://carnivalxchange.com/getposts"
			const share_this = encodeURIComponent(`${base_url}/${post_id}/${user_id}`)
			const share_url = "https://www.facebook.com/sharer/sharer.php?u=" + share_this

			// https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.fineahban.com%2Fposts%2F148880
			// https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fcarnival-xchange.firebaseapp.com%2FCzo3LMUFaQYQC9XOyVf5%2FRKXBIKPxIkb1Y9PSkJCBjIpdb9h1
			console.log("share url", share_url)
		}
	}



	function openUserMessages(uid, username, msgRef){
		//console.log("user id:", uid, ", username", username, ", msgref:" , msgRef )
		navigate("/post/message/new", {
			state: {
				userId: uid,
				username: username,
				mesageReference: msgRef
			}
		});
	}

	const searchItems = (e) => {
		e.preventDefault();
		setActive(e.target.id);
		console.log(e.target.id)
		const searchValue = e.target.value ? e.target.value : ""

		if (searchValue !== '') {
			if(searchValue === "all"){
				setFilteredResults(posts);
				return
			} 
            //console.log(filteredResults)
			const results = posts.filter((item) => {
			return item.category.toLowerCase().includes(searchValue.toLowerCase());  
			});
			setFilteredResults(results);
		} else {
			setFilteredResults(posts);
		}

		// setHiddenBlock(false)
    }

	function openFilters(){
		if(hiddenBlock){
			setHiddenBlock(false)
		}
		else{
			setHiddenBlock(true)
		}
	}

	




return (
	<>
		        <div  key={0} className="xch-container xch-main-header">
                    <div className="xch-header-flex">
                        <div className="xch-header-a"><Button variant="link" onClick={() => { openFilters()}} ><Filter/>&nbsp;Filter</Button></div>
                        <h2 className="text-center xch-header-c">Listings</h2>
                        <div className="xch-header-b"><Button variant="link" href="/post/new"><Plus/> New</Button></div>
                    </div>
                </div>
				<Container key={1}  className={(hiddenBlock ? "xch-hide-block" : "")}>
					<Row className="xch-container-inner-sm" >
						<Col className="xch-filters">
							{/* <Button key={0} variant="light" onClick={() => { searchItems("all")}} >All</Button> */}
							<Button className={active == 0 ? "active" : undefined} id={0} value="all" variant="light" onClick={searchItems} >All</Button>
							<Button className= {active == 1 ? "active" : undefined} id={1} value="tickets"  variant="light" onClick={searchItems}  >Tickets</Button>
							<Button className= {active == 2 ? "active" : undefined} id={2} value="costume"  variant="light" onClick={searchItems}  >Costumes</Button>
							<Button className= {active == 3 ? "active" : undefined} id={3} value="accommodation" variant="light" onClick={searchItems}  >Accommodation</Button>
							<Button className= {active == 4 ? "active" : undefined} id={4} value="transportation"  variant="light" onClick={searchItems}  >Transportation</Button>
						</Col>
					</Row>
				</Container>

			
				<Container key={2}  className="xch-container-pos">
					<Row  key={0} className="xch-container-inner-sm">
						<Col  key={0} >
							<InfiniteScroll
								dataLength={filteredResults.length}
								next={() => {
                                    fetchData(setPosts, setFilteredResults, filteredResults, setItems, items);
                                  }}
                                hasMore={true}

								// loader={<h4>Loading...</h4>}
								// endMessage={
								// <p style={{ textAlign: 'center' }}>
								// 	<b>You've reached the end.</b>
								// </p>
								// }

                                // below props only if you need pull down functionality
                                refreshFunction={refresh}
                                pullDownToRefresh
                                pullDownToRefreshThreshold={50}
                                pullDownToRefreshContent={
                                <h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>
                                }
                                releaseToRefreshContent={
                                <h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>
                                }
							>
								{posts &&
									filteredResults.map(({ id, description, image, userid, userPhoto, category, createdAt, photoUrl, displayName, username }) => {
										const base_url = "https://carnivalxchange.com/getposts"
										const share_this = encodeURIComponent(`${base_url}/${id}/${userid}`)
										const share_url = "https://www.facebook.com/sharer/sharer.php?u=" + share_this
									return (
										<div  key={id} >
											<Card className="xch-post-card" key={id}>
												<div key={0}  className="xch-post-head">
													<div  key={0} className="xch-post-head-left">
														{  userPhoto ? ( <img src={userPhoto ? userPhoto : ""}/> ) : (<Avatar name={username} size="40" />)}
													</div>
													<div  key={1} className="xch-post-head-right">
														<div  key={0} className="xch-post-head-name">{username}</div>
														<div  key={1} className="xch-post-head-time">
															<TimeAgo 
															key={0} 
															date={
																new Date(createdAt.toDate())
															} />
														</div>
													</div>
												</div>
												
												<div key={1}  className="xch-post-image-container">
													{ image ?  (
														<>

														{/* <Gallery>
															<Item
															original={image}
															thumbnail={image}
															width="1200"
															height="400"
															>
															{({ ref, open }) => (
																<img
																ref={ref}
																onClick={open}
																src={image}
																/>
															)}
															</Item>
														</Gallery> */}

													
														<div className="xch-bg-image" style={{backgroundImage:"url("+image+")", 
															backgroundSize:'container',
															backgroundRepeat:'no-repeat',

															}}>
															<ModalImage
																small={Ximg}
																classsName="xch-img-blank"
																large={image}
																key={0} 
															/> 
														</div>
														{/* <LazyLoad height={300} threshold={0.95}> */}
																{/* <ModalImage
																	small={image}
																	large={image}
																	key={0} 
																/>  */}
															{/* </LazyLoad> */}
														
														
															{/* <LazyLoad height={lazyHeight} threshold={0.95}>
																<Zoom>
																	<img
																	alt=""
																	src={image}
																	loading="lazy"
																	width="100%"
																	height="300px"
																	/>
																</Zoom>
															</LazyLoad> */}
														
														</>

													// <LazyLoad height={lazyHeight} threshold={0.95}>
														
													// 	<Card.Img className="xch-post-image" variant="top" src={image ? image : ""}/> 
													// </LazyLoad>
													) : (<></>) }
												</div>
												<Card.Body  key={2} >
													<Card.Text  key={0}  className="xch-description">
													{description}
													</Card.Text>
													<div  key={1} className="xch-share-section">
														{ (loggedInUserId != userid) ?
															(
															<><Button  key={0}  className="xch-btn-msg" variant="link" onClick={() => {openUserMessages(userid, username, id )}}>Message</Button>&nbsp;</>
															): 	(
															<></>
															)
														}
														{
														canShareMobile ? (
														<Button  key={1} className="xch-btn-share btn-share" variant="link" onClick={() => { sharePost(
															"Carnival-Xchange", (description ? description : "-"), (image) ? image : "", userid, id)  }}><Share />&nbsp;Share</Button>
															):(<></>)
														}
															&nbsp;<Button  key={2} target="_blank" rel="nofollow" className="xch-btn-share btn-fb" variant="link" href={share_url}><Facebook />&nbsp;Facebook</Button>
														
													</div>
												</Card.Body>
											</Card>
										</div>
									);
								})}
							</InfiniteScroll>
						</Col>
					</Row>
				</Container>

                <Container  className="xch-container" key={3} >
                    <div className="xch-disclaimer">
                    <strong>DISCLAIMER:</strong> The past few years have seen a rise in fraudulent transactions across several platforms. 
					Transactions conducted on this website/platform are strictly between the seller and buyer. Ticket Federation nor
					Carnival Xchange accept any liability for transactions which may result in monetary loss to the buyer.
					Buyers should obtain a valid phone number from the seller and arrangements to meet at a safe location
					be made. In the case of e-ticket and costume transfers, the buyer should demand a copy of the seller's
					ID before any transfer of funds is made.
                    </div>
                </Container>
	</>
)
};

export default Posts;

const root = {
display: "flex",
flexDirection: "row",
flex: 1,
width: "100%",
};

const left = {
display: "flex",
flex: 0.2,
height: "95vh",
margin: 10,
flexDirection: "column",
};

const right = {
display: "flex",
flex: 0.8,
height: "95vh",
margin: 10,
flexDirection: "column",
};

const input = {
flex: 1,
outline: "none",
borderRadius: 5,
border: "none",
};

const messagesDiv = {
backgroundColor: "#FBEEE6",
padding: 5,
display: "flex",
flexDirection: "column",
flex: 1,
maxHeight: 460,
overflowY: "scroll",
};
