import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { Button, Divider, IconButton } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { ArrowLeft } from 'react-bootstrap-icons';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { db, auth } from "../services/Firebase";
import {
addDoc,
doc,
getDoc,
collection,
collectionGroup,
onSnapshot,
where,
getDocs,
orderBy,
query,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Navigate, useLocation } from "react-router-dom";

function UsersComponent(props) {
	const handleToggle = (username, userId, photoUrl) => {
		props.setRecieverData({
		username: username,
		userId: userId,
		userPhoto: photoUrl,
		});
		props.setHideMessageBoard(false)
		props.setHideUsers(true)
		//console.log(props);
	};

return (
	<List
	dense
	sx={{ width: "100%", maxWidth: 360,
	}}
	>
	{props.users?.map((value, index) => {
		const labelId = `checkbox-list-secondary-label-${value}`;

		if (props.currentUserId !== value.userId)
		return (
			<ListItem key={value.userId} disablePadding>
			<ListItemButton
				onClick={() => {
				handleToggle(value.username, value.userId, value.photoURL);
				}}
			>
				<ListItemAvatar>
					<Avatar
						alt={`${value.username}`}
						src={value.photoURL ? value.photoURL : value.username+'.jpg'}
					/>
				</ListItemAvatar>
				<ListItemText id={labelId}
						primary={`${value.username}`} />
			</ListItemButton>
			</ListItem>
		);
	})}
	</List>
);
}

const Messages = () => { 

	const location = useLocation()
	const un = location.state != null ? location.state.username : ""
	const ui = location.state != null ? location.state.userId : ""
	const up = location.state != null ? location.state.userPhoto : ""
	const msgRef = location.state != null ? location.state.mesageReference : ""
	const [isSignedIn, setSignedIn] = useState(false)
	const [users, setUsers] = useState([]);
	const [hideMsgBoard, setHideMessageBoard] = useState(true)
	const [hideUsers, setHideUsers] = useState(false)
	
	const [messageReference, setMessageReference] = useState((msgRef) ? msgRef : "");
	const [recieverData, setRecieverData] = useState( 
											(un != "" && ui != "" ) ?
												{
													username: un,
													userId: ui ,
													userPhoto: up
												}
											: null
											);
	const [chatMessage, setChatMessage] = useState("");
	const [allMessages, setAllMessages] = useState([]);
	const [allMyUsers, setAllUsers] = useState([]);
	const [userid, setUserId] = useState("")
    const [username, setUserName] = useState("")
    const [userPhoto, setUserPhoto] = useState("")
	const user = auth.currentUser;
	const [scrDimensions, setScrDimensions] = useState({});
	const [msgWindowHeight, setMsgWindowHeight] = useState({height:"100px"});
	
	const navigate = useNavigate();
	var displayPhoto = ""
	var displayName = ""
	displayPhoto = localStorage.getItem("displayPhoto") ? localStorage.getItem("displayPhoto") : ""
	displayName = localStorage.getItem("displayName") ? localStorage.getItem("displayName") : ""
	
	useEffect( () => {
		onAuthStateChanged(auth, (user) => {
			if (user) {
				//console.log(userid)
				async function fetchData() {
					// try {
						console.log(userid)
						// const colRef = collection(db, "users", "6BjLSovdCZMdJq1cfMa9Vn1jUXr2", "chatusers")
						// const testSnap = await getDocs(colRef);
						// if(testSnap.docs.length > 0) {
						// 	testSnap.forEach(doc => {
						// 		console.log(doc.data())
						// 	})
						// }  
						var docId = "";
						var savedAllUsers = []
						const colRef = collection(db, "users", userid, "chatusers")
						const usersRef = collection(db, "users");
						const docsSnap = await getDocs(colRef);
						if(docsSnap.docs.length > 0) {
							docsSnap.forEach(doc => {
								var docId = doc.id
								// store all the users I chat with in a state variable 
								savedAllUsers.push(docId)
								// get the user information and store in user info onject
								console.log("DOCUMENT ID:- " + docId); 
							})
							console.log("Allusers: " + savedAllUsers); 
							// const q = query(usersRef, where('userId', 'in', allMyUsers));
							const q = query(usersRef, where('userId', 'in', savedAllUsers));
							const querySnapshot = await getDocs(q);
							if(querySnapshot.docs.length > 0) {
								setUsers(querySnapshot.docs.map((doc) => doc.data()));
								var docData = querySnapshot.docs.map((doc) => doc.data())
								console.log("User found: ", querySnapshot.docs.map((doc) => doc.data()) )
							}  
						}    
					// } catch(error) { 
					// 	console.log(error)
					// }
				} 
				fetchData();
			} else {
				setSignedIn(false);
				navigate("/signin");
			}
		})
	}, [userid]);
	

	useEffect(() => {
		function getWindowSize() {
			const {innerWidth, innerHeight} = window;
			var headingHeight = innerHeight-136-95
			if(innerWidth > 480){headingHeight = innerHeight-136-95}
			else if(innerWidth < 570){headingHeight = innerHeight-116-95}
			setMsgWindowHeight( {height: headingHeight, display:"flex",flexDirection: "column", flex: 1, width: "100%",})
			setScrDimensions({inWidth: innerWidth, inHeight: innerHeight})
		  }
		  getWindowSize()
			onAuthStateChanged(auth, (user) => {
			if (user) {
				if(location.state != null)
				{
					//setRecieverData({userId:location.state.userId, username: location.state.username})
				}
				const uid = user.uid;
				setSignedIn(true);
				setUserId(user.uid ? user.uid : "")
				setUserName(user.displayName ? user.displayName : "")
				setUserPhoto(user.photoURL ? user.photoURL : "")
				// console.log("User is signed in " + user.uid)				
				async function fetchData(){
					try {
						if (recieverData) {
							//console.log(recieverData)
							const unsub = onSnapshot(
								query(
								collection(
									db,
									"users",
									user?.uid,
									"chatusers",
									recieverData?.userId,
									"messages"
								),
								orderBy("timestamp")
								),
								(snapshot) => {
									setAllMessages(
										snapshot.docs.map((doc) => ({
										id: doc.id,
										messages: doc.data(),
										}))
									);
								}
							);
						return unsub;
						}
					} catch(error) {
						console.log(error)
					}
				}
				fetchData();   
			} else {
				setSignedIn(false);
				navigate("/signin");
			}
		});	
	}, [recieverData?.userId, messageReference, hideMsgBoard]);


	const sendMessage = async () => {
		if(chatMessage=="") return	//if message is empty do not send mesage
		
		try {
			if (user && recieverData) {
				console.log(`USER : ${user.uid} `)
				console.log(`RECEIVER : ${recieverData.userId}` )


				await addDoc(
				collection(
					db,
					"users",
					user.uid,
					"chatusers",
					recieverData.userId,
					"messages"
				),
				{
					username: user.displayName,
					messageUserId: user.uid,
					message: chatMessage,
					timestamp: new Date(),
				}
				);

				await addDoc(
				collection(
					db,
					"users",
					recieverData.userId,
					"chatusers",
					user.uid,
					"messages"
				),
				{
					username: user.displayName,
					messageUserId: user.uid,
					message: chatMessage,
					timestamp: new Date(),
				}
				);
			}
		} catch (error) {
			console.log(error);
		}
		setChatMessage("");
	};
return (
	<>
		<Container fluid className="xch-page-title">
			<div className="xch-main-header xch-container ">
				{!hideMsgBoard ?(<div className="xch-header-msg-a"><Button variant="link" onClick={() => { setHideMessageBoard(true)}} ><ArrowLeft/>&nbsp;Back</Button></div>):(<></>)}
				
					{ hideMsgBoard ? (<div className="xch-w">Messages</div>) 
					: (
						<div className="xch-header-msg-h">
								<span className="xch-w"> <span className="xch-chat-with">Chat with: </span>{recieverData.username} </span>
							
							<span className="xch-heade-avatar"> <Avatar src={recieverData.userPhoto} size="20" /> </span>
						</div>
					)}

					{/* <span><Avatar src={recieverData.username} size="20" /></span> */}
					
				
			</div>
		</Container>
		
		{ isSignedIn ? (
			
				<Container className="xch-container">
					<Row>
						<Col>

					<div className="xch-messages-root" style={root}>
						
					<Paper className={!hideMsgBoard ? "hideUsers xch-messages-root-left" : "xch-messages-root-left"} 
					style={msgWindowHeight}
					>
						<div
						style={{
							padding: 5,
							justifyContent: "space-between",

						}}
						>
						<div className="xch-post-head">
							<div className="xch-post-head-left"><img src={displayPhoto}/></div>
							<div className="xch-post-head-right">
								<div className="xch-post-head-name xch-mesages-myname">{displayName} </div>
							</div>
						</div>
						
						</div> 
						<Divider />
						{ users.length > 0 ? (<div className="xch-label-margin">Select user below</div>) : 
						(
							<div className="xch-label-margin">No chats yet. <br/></div>
						)}
						<div style={{ overflowY: "scroll" }}>
						<UsersComponent
							users={users}
							setRecieverData={setRecieverData}
							setHideMessageBoard={setHideMessageBoard}
							setHideUsers={setHideUsers}
							navigate={navigate}
							currentUserId={user?.uid}
						/>
						</div>
					</Paper>

					<Paper  className={hideMsgBoard ? "hideMsgBoard xch-messages-root-right" : "xch-messages-root-right"} 
					style={msgWindowHeight}

					>
						{/* <div>
							{recieverData ? (
								<>
									<span className="xch-chat-with">Chat with </span>
									<span className="xch-chat-name">
										{recieverData ? recieverData.username : ""} 
									</span>
								</>
							)
							: ("")
							}
						</div> */}
						
						

						<Divider />
						<div style={messagesDiv}>
						{/* messages area */}

						{allMessages &&
							allMessages.map(({ id, messages }) => {
							return (
								<div
								key={id}
								style={{
									margin: 2,
									display: "flex",
									flexDirection:
									user?.uid == messages.messageUserId
										? "row-reverse"
										: "row",
								}}
								>
								<span
									style={{
									backgroundColor: user?.uid == messages.messageUserId ? "#e1fec9" : "#f9f9f9",
									padding: 6,
									borderTopLeftRadius:
										user?.uid == messages.messageUserId ? 10 : 0,
									borderTopRightRadius:
										user?.uid == messages.messageUserId ? 0 : 10,
									borderBottomLeftRadius: 10,
									borderBottomRightRadius: 10,
									maxWidth: 400,
									fontSize: 16,
									textAlign:
										user?.uid == messages.messageUserId ? "right" : "left",
									}}
								>
									{messages.message}
								</span>
								</div>
							);
							})}
						</div>

						<div className="xch-msg-send" style={{ width: "100%", borderBottom:"1px solid #eee", display: "flex" }}>
						<input
							value={chatMessage}
							onChange={(e) => setChatMessage(e.target.value)}
							style={input}
							type="text"
							placeholder="Type message..."
						/>
						<IconButton onClick={sendMessage} >
							<SendIcon style={{ margin: 10 }} />
						</IconButton>
						</div>
					</Paper>
					</div>
					
						</Col>
							
						</Row>
				</Container>
			):(	<></> )
		}
	</>
)
};

export default Messages;

const root = {
display: "flex",
flexDirection: "row",
flex: 1,
width: "100%",
};

const left = {
display: "flex",
flex: 0.3,
height: "95vh",
margin: 10,
flexDirection: "column",
};

const right = {
display: "flex",
flex: 0.7,
// height: "95vh",
margin: 10,
flexDirection: "column",
};

const input = {
flex: 1,
outline: "none",
paddingLeft:15,
borderRadius: 5,
border: "none",
};

const messagesDiv = {
backgroundColor: "#eee",
padding: 5,
display: "flex",
flexDirection: "column",
flexGrow: 1,
overflowY: "scroll",
};
