import {React, Component, useState, useMemo} from 'react';
import ReactDOM from 'react-dom/client';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';


const Privacy = () => {

        return (
            <>
            <div className="xch-main-header xch-container ">
                <h2 className="text-center ">Privacy Policy</h2>
            </div>
            <Container>
                <Row>
                    <Col>
                    <div>
                    <p><strong>Welcome to Carnival-Xchange™ </strong></p>
                    <p>We want you to know that protecting your personal information is very important to us, and we respect your privacy. This Privacy Policy is meant to explain how we use and protect the personal information you share with us. This Policy applies to all users of <a href="http://www.carnival-xchange.com/">www.carnival-xchange.com</a> or our services, and to any information provided to us. <br/><br/>By using our website, you are accepting the practices we describe here. <br/><br/><strong>Consumer Privacy at Carnival-Xchange™</strong><br/>At Carnival-Xchange™, we respect the privacy of our users and the importance of the information they entrust to us. We summarize below the basic information policies and practices in place on the Carnival-Xchange™ website. <br/><br/><strong>Information we may collect</strong>&nbsp;&nbsp;<br/><em>In General.</em> We may collect personal information that can identify you such as your name and email address and other information that does not identify you. When you provide personal information through our website, the information may be sent to servers located in the United States and other countries around the world.</p>
                    <ul>
                    <li><strong>Information you provide.</strong> We may collect and store any personal information you enter on our website or provide to us in some other manner. This includes identifying information, such as your name, address, e-mail address, and telephone number, and, if you transact business with us, financial information such as your payment method (valid credit card number, type, expiration date or other financial information). We also may request information about your interests and activities, your gender and age, and other demographic information.</li>
                    <li><strong>Information from other sources.</strong> We may also periodically obtain both personal and non-personal information about you from our business partners, contractors and other third parties. Examples of information that we may receive include: updated delivery and address information, purchase history, and additional demographic information.</li>
                    </ul>
                    <p><em>Use of cookies and other technologies to collect information</em>. We use various technologies to collect information from your computer and about your activities on our site.</p>
                    <ul>
                    <li><strong>Information collected automatically.</strong> We automatically collect information from your browser when you visit our website. This information includes your IP address, your browser type and language, access times, the content of any undeleted cookies that your browser previously accepted from us (see “Cookies” below), and the referring website address.</li>
                    <li><strong>Cookies.</strong> When you visit our website, we may assign your computer one or more cookies [link], to facilitate access to our site and to personalize your online experience. Through the use of a cookie, we also may automatically collect information about your online activity on our site, such as the web pages you visit, the links you click, and the searches you conduct on our site. Most browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies. If you choose to decline cookies, please note that you may not be able to sign in or use some of the interactive features offered on our website.</li>
                    <li><strong>Other Technologies.</strong> We may use standard Internet technology, such as web beacons, and other similar technologies, to track your use of our site. We also may include web beacons in promotional e-mail messages or newsletters to determine whether messages have been opened and acted upon. The information we obtain in this manner enables us to customize the services we offer our website visitors to deliver targeted advertisements and to measure the overall effectiveness of our online advertising, content, programming or other activities.</li>
                    <li><strong>Information collected by third-parties. </strong>We may allow third-parties, including our authorized service providers, advertising companies, and ad networks, to display advertisements or provide applications and services on our site. These companies may use tracking technologies, such as cookies, or web beacons to collect information about users who view or interact with their advertisements or use their applications and services. Our website does not provide any personal information to these third parties. This information allows them to deliver targeted advertisements and personalized online experiences and gauge their effectiveness.</li>
                    </ul>
                    <p><br/><strong>How we may use information we collect</strong>&nbsp;&nbsp;&nbsp;<br/>We may use information to:</p>
                    <ul>
                    <li>Fulfill your requests for products and services;</li>
                    <li>Offer products and services that may be of interest to you;</li>
                    <li>Customize the advertising and content that you see on our website;</li>
                    <li>Facilitate use of our website;</li>
                    <li>Manage your account and your preferences;</li>
                    <li>Analyze use of and improve our website, products and services;</li>
                    <li>Identify and protect against fraudulent transactions and other misuses of our website; and</li>
                    <li>Enforce our Terms of Use.</li>
                    </ul>
                    <p><strong>With whom we may share information we collect</strong>&nbsp;&nbsp;&nbsp;<br/>We will not share your personal information with others except as indicated below, or except when we inform you in advance and give you the opportunity to opt out. We may share personal information with:</p>
                    <ul>
                    <li>Event providers, such as the venues, promoters, artists’ representatives and fan clubs, teams, leagues and others who are involved in, produce or bring you events;</li>
                    <li>Carnival-Xchange™-owned or affiliated businesses, in order to provide you with information about a variety of products and services that might interest you;</li>
                    <li>Service providers, such as credit-card payment processors, performing services on our behalf;</li>
                    <li>Other businesses with which we partner or which we carefully select to offer you products, services, and promotions through our website or offline; and</li>
                    <li>Other third parties in limited circumstances, such as complying with legal requirements, preventing fraud, and protecting the safety of our users.</li>
                    </ul>
                    <p>We may share aggregated, non-personal information in any of the above situations and also with advertisers and others. <br/><br/><strong>Your choices</strong>&nbsp;&nbsp;&nbsp;<br/>You may choose:</p>
                    <ul>
                    <li>Not to provide personal information, although that may result in your inability to obtain certain services or use certain features of our website;</li>
                    <li>To stop receiving promotional emails or newsletters from us by sending an email to the contact address at the bottom of this policy;</li>
                    <li>To delete or decline cookies by changing your browser settings, although if you do so, some of the features or services of our website may not function properly;</li>
                    <li>To review and update your personal information by contacting us at the email address below.</li>
                    </ul>
                    <p><strong>Security</strong>&nbsp;&nbsp;&nbsp;<br/>We take appropriate physical, electronic, and other security measures to help safeguard personal information from unauthorized access, alteration, or disclosure.<br/>Our website is a general audience site, and we do not knowingly collect personal information from children under the age of 16.<br/><br/><strong>Changes to our Privacy Policy</strong><br/>We may occasionally update our Privacy Policy to reflect changes in our practices and services. If we make material changes in the way we collect, use, or share your personal information, we will notify you by sending you an email to the email address you most recently provided to us and/or by prominently posting notice of the changes on our website.<br/><br/><strong>How to contact us</strong><br/>If you have any questions about this summary, our Privacy Policy, or our information practices, please contact us by email: <a href="mailto:info@carnival-xchange.com">info@carnival-xchange.com</a></p>

                    </div>
                    </Col>
                </Row>    
            </Container>
            </>
        )


    

}

export default Privacy;