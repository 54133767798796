import {React, Component, useState, useMemo} from 'react';
import ReactDOM from 'react-dom/client';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { ArrowLeftCircleFill } from 'react-bootstrap-icons';
import { ArrowLeft } from 'react-bootstrap-icons';

import { db, auth, storage} from "../services/Firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { useAuthContext } from '../context/auth';
import { onAuthStateChanged } from "firebase/auth";



import {
    collection,
    addDoc,
    doc,
    getDoc,
    onSnapshot,
    serverTimestamp,
    orderBy,
    query
} from 'firebase/firestore';




const NewPost = () => {
    const [imgUrl, setImgUrl] = useState(null);
    const [isSignedIn, setSignedIn] = useState(false)
    const [currentUserId, setUserId] = useState(null)
    const [progresspercent, setProgresspercent] = useState(0);
    const { user } = useAuthContext()
    const [newPostCategory, setNewPostCategory] = useState("")
    const [newPostText, setNewPostText] = useState("")
    const [newPostImage, setNewPostImage] = useState("")
    const [posts, setPosts] = useState([])
    const dataRef = collection(db, "posts")
    const navigate = useNavigate();
    const [canAddImage, setCanAddImage] = useState(0)
    const [selectedCategory, setSelectedCategory] = useState("")
    const [charCount, setCharCount] = useState(0)
    const [characterLimit] = useState(1000);
    const [inputText, setInputText] = useState("");

    const [username, setUserName] = useState("")
    const [userPhoto, setUserPhoto] = useState("")

    const handleTextAreaChange = event => {
        setInputText(event.target.value);
        setCharCount(event.target.value.length)
      };
    
    
    useEffect(() =>{ 
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const uid = user.uid;
                setSignedIn(true);
                setUserId(user.uid ? user.uid : "")

                const docRef = doc(db, "users", uid);
                const docSnap = await getDoc(docRef);
                if(docSnap.exists()) {
                    console.log(docSnap.data());
                    setUserName(docSnap.data().username ? docSnap.data().username : "")
                    setUserPhoto(docSnap.data().photoURL ? docSnap.data().photoURL : "")
                } 
                // console.log(user.displayName)
                // console.log("User is signed in " + user.uid)
        } else {
            // User is signed out
            setSignedIn(false);
            navigate("/signin");
        }
        });
    }, [currentUserId]);
    


    function checkCategory(category){
        console.log(category)
        
        if(category == "costume" || category == "accommodation")
        {
            setCanAddImage(1)
            return
        }
        else{
            setCanAddImage(0)
            return
        }
    }

   


    const addPost = async (e) => {
        e.preventDefault()
        // 1. save the image and get the returned URL
        // (category == "costume" || category == "tickets")
        const file = e.target[2]?.files[0]
        const description = e.target[1].value ? e.target[1].value : ""
        const category = e.target[0].value ? e.target[0].value : ""

        if(category == "" || description == "")
        {
            alert("Please select a category and enter a description for your item.")
            return
        }


        if(   ((category == "transportation" || category == "tickets") && file)   ||   
              ( !file ) )
        {
            const userPostsRef = 
                collection(db, "users", currentUserId, "posts" )
                console.log(username);
                setImgUrl("")
                await addDoc(userPostsRef, {
                    userid: currentUserId ? currentUserId : "",
                    username: username ? username : "",
                    description: description,
                    category: category,
                    createdAt: serverTimestamp(),
                }).then(docRef => {
                    console.log(docRef);
                    navigate(`/post/${docRef.id}`)
                })
        }
        else
        {
            const storageRef = ref(storage, `files/${currentUserId}/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
            console.log("UserID = " + currentUserId)
            console.log("Description = " + description)
            console.log("Category = " + category)
            uploadTask.on("state_changed",
            (snapshot) => {
                const progress =
                Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgresspercent(progress);
            },
            (error) => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                    // 2. save the image url in the post message
                    const userPostsRef = 
                    collection(db, "users", currentUserId, "posts" )
                    console.log(currentUserId);
                    setImgUrl(downloadURL ? downloadURL : "")
                    
                    await addDoc(userPostsRef, {
                        userid: currentUserId ? currentUserId : "",
                        userPhoto: userPhoto ? userPhoto : "",
                        username: username ? username : "",
                        description: description,
                        image: downloadURL,
                        category: category,
                        createdAt: serverTimestamp(),
                    }).then(docRef => {
                        console.log(docRef);
                        navigate(`/post/${docRef.id}`)
                    })


                    console.log("UserID = " + currentUserId)
                    console.log("Description = " + description)
                    console.log("Category = " + category)
                    console.log("Image = " + downloadURL)
                });
            }
            );
        }





        if (!file) return;
        
        
        
    }



        return (
            <>
            <div className="xch-main-header xch-container ">
                <div className="xch-header-flex">
                    <div className="xch-header-a"><Button variant="link" href="/posts"><ArrowLeft/></Button></div>
                    <h2 className="text-center xch-header-c">New Listing</h2>
                    <div className="xch-header-b"></div>
                </div>
            </div>
            
            <Container>
                <Row className="xch-contact">
                    <Col>
                    <div>
                        <Form onSubmit={addPost}>
                                <Form.Text className="text-muted xch-label">
                                   Select Item Category
                                </Form.Text>
                                {/* elemnt 0 */}
                                <Form.Select 
                                    aria-label="Select post category" 
                                    onChange={(e) => {checkCategory(e.target.value)}}
                                >
                                    <option value="tickets">Tickets</option>
                                    <option value="costume">Costume</option>
                                    <option value="accommodation">Accommodation</option>
                                    <option value="transportation">Transportation</option>
                                </Form.Select>
                                <Form.Group className="mb-3" controlId="form.ControlTextarea1">
                                    <Form.Label className='xch-label'>Item Description &nbsp;<span className="xch-sm-txt">({charCount}/1000)</span></Form.Label>
                                    <Form.Control as="textarea" 
                                        value={inputText} 
                                        onChange={handleTextAreaChange} 
                                        // onChange={e => setCharCount(e.target.value.length)}
                                        isInvalid={(inputText.length > characterLimit)}// value={newPostText}
                                        maxlength="1000"
                                    // onChange={ (e) => onChangeDescription(e.target.value) }
                                    placeholder="Describe the item you have for sale, exchange or looking for. Give as much details as possible." rows={5} />
                                </Form.Group>
                                 {/* elemnt 2 */}
                                 {
                                !imgUrl &&
                                    <div className='outerbar'>
                                    <div className='innerbar' style={{ width: `${progresspercent}%` }}>{progresspercent}%</div>
                                    </div>
                                }
                                {
                                    imgUrl &&
                                    <img src={imgUrl} alt='uploaded file' height={200} />
                                }
                                <Form.Group className="mb-3" controlId="form">
                                    <Form.Label className=' xch-label '>Upload image &nbsp;<span className="xch-sm-txt">(Max-Size: 2MB)</span> </Form.Label>
                                    <div className="file-upload-wrapper">
                                    { canAddImage==1 ? ( <input type='file' /> ) : ( <input disabled type='file' /> ) }
                                    </div>
                                </Form.Group>
                               

                                <Button variant="primary" type="submit">
                                    Make Post
                                </Button>
                        </Form>
                        
                    </div>
                    </Col>
                </Row>    
            </Container>
            </>
        )


    

}

export default NewPost;