import {React, Component, useState, useMemo} from 'react';
import ReactDOM from 'react-dom/client';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { ArrowLeftCircleFill } from 'react-bootstrap-icons';
import { db, auth, storage} from "../services/Firebase";
import { ref, getStorage, getDownloadURL, uploadBytesResumable, deleteObject } from "firebase/storage";
import { useAuthContext } from '../context/auth';
import { onAuthStateChanged } from "firebase/auth";

import {
    collection,
    addDoc,
    getDoc,
    deleteDoc,
    doc,
    updateDoc,
    onSnapshot,
    serverTimestamp,
    orderBy,
    query
} from 'firebase/firestore';

const EditPost = () => {
    const [imgUrl, setImgUrl] = useState(null);
    const [isSignedIn, setSignedIn] = useState(false)
    const [progresspercent, setProgresspercent] = useState(0);
    const { user } = useAuthContext()
    const [newPostCategory, setNewPostCategory] = useState("")
    const [newPostText, setNewPostText] = useState("")
    const [newPostImage, setNewPostImage] = useState("")
    const [posts, setPosts] = useState([])
    const dataRef = collection(db, "posts")
    const navigate = useNavigate();
    const [userid, setUserId] = useState("")
    const [postid, setPostId] = useState("")
    const [username, setUserName] = useState("")
    const [userPhoto, setUserPhoto] = useState("")
    const [selectedCategory, setSelectedCategory] = useState("")
    const [postImage, setPostImage] = useState("")
    const [postRef, setPostRef] = useState("")
    const [canAddImage, setCanAddImage] = useState(0)
    const [charCount, setCharCount] = useState(0)
    const [characterLimit] = useState(1000);
    const [inputText, setInputText] = useState("");


    useEffect(() =>{ 
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid;
                setSignedIn(true);
                setUserId(user.uid ? user.uid : "")
                setUserName(user.displayName ? user.displayName : "")
                setUserPhoto(user.photoURL ? user.photoURL : "")
                console.log("User is signed in " + user.uid)
                console.log("User is signed in " + user.uid)
            
 
                async function fetchData(){
                    console.log("user id: " + uid)
                    const windowPath = window.location.pathname ? window.location.pathname : ""
                    const sliced_path = windowPath.slice(1)
                    const pathArray = sliced_path.split("/");
                    let post_id = pathArray[1] ? pathArray[1] : "";
                    setPostId(post_id);
                    console.log("post id: " + post_id)
                    const docRef = doc(db, "users", uid, "posts", post_id);
                    const docSnap = await getDoc(docRef);
                    try {
                        const docSnap = await getDoc(docRef);
                        if(docSnap.exists()) {
                            console.log(docSnap.data());
                            if(docSnap.data().category) checkCategory(docSnap.data().category)
                            setPosts(docSnap.data());
                            setPostRef(docRef)
                            setSelectedCategory(docSnap.data().category ? docSnap.data().category : "")
                            setNewPostText(docSnap.data().description ? docSnap.data().description : "")
                            setPostImage(docSnap.data().image ? docSnap.data().image : "")
                        } else {
                            console.log("Document does not exist")
                        }
                    } catch(error) {
                        console.log(error)
                    }
                }
        fetchData();
        } else {
            // User is signed out
            setSignedIn(false);
            navigate("/signin");
        }
        });
    }, [imgUrl]);

    function checkCategory(category){
        console.log(category)
        setSelectedCategory(category)
        
        if(category == "costume" || category == "accommodation")
        {
            setCanAddImage(1)
            return
        }
        else{
            setCanAddImage(0)
            return
        }
    }

    function deleteCurrentImage(fileUrl, postRef){
        const deleteReference = ref(storage, fileUrl)
        console.log("Storage Reference: "+deleteReference)
        const deleteRef = ref(storage, deleteReference);
        // Delete the file
        deleteObject(deleteRef).then(() => {
            console.log("File Successfuly deleted")
            setPostImage("")
            //clear from the post document
            var data = {image:""};
            updateDoc(postRef, data)
            .then(docRef => {
                console.log("Updated")
            })
            .catch(error => {
                console.log(error);
            })
        }).catch((error) => {
            console.log("Could not delete file.")
        });

        return

    }

    async function deletePost(){
        const docRef = doc(db, "users", userid, "posts", postid);
        // Delete post
        await deleteDoc(docRef);
        //vavigate to my posts
        navigate("/myposts");
        return

    }

    const editPost = async (e) => {
        e.preventDefault()
        // console.log(e.target[4])
        const currentImage = e.target[4].value ? e.target[4].value : ""
        // 1. save the image and get the returned URL
        const file = e.target[3]?.files[0] ? e.target[3]?.files[0] : ""
        const description = e.target[1].value ? e.target[1].value : ""
        const category = e.target[0].value ? e.target[0].value : ""

        if(description=="" || category==""){return}

        const docRef = doc(db, "users", userid, "posts", postid);
        var data = {
            category: category,
            description:description
        };
        
        function updatePostDocument(fileUrl){
            console.log("File URL: "+fileUrl)
            if(fileUrl != ""){data.image = fileUrl}
            const storageRef = ref(storage, `files/${userid}/${file.name}`);
            console.log(data)
            console.log("------------------------")
            console.log(storageRef)
            console.log("------------------------")
            
            updateDoc(docRef, data)
            .then(docRef => {
                console.log("Updated")
                //navigatt to preview page
                navigate(`/post/${postid}`) 
            })
            .catch(error => {
                console.log(error);
            })
        }

        console.log("__________________________________")
        

        
        //################ delete the existing file
        if(currentImage != "" && file && (currentImage == file.name))
        {
            console.log(file)
            // Create a reference to the file to delete
            const storageRef = ref(storage, `files/${userid}/${file.name}`);
            console.log("Reference name: " + storageRef.name)
            // console.log("File Name:",image_name);
            const deleteRef = ref(storage, `files/${userid}/${storageRef.name}`);
            // Delete the file
            deleteObject(deleteRef).then(() => {
            // File deleted successfully
            console.log("File Successfuly deleted") 
            }).catch((error) => {
            // Uh-oh, an error occurred!
            console.log("Could not delete file.")
            });
            // return
            //update the post document
            updatePostDocument("")
        }
        else if(   (currentImage != ""  && !file) 
                || (   (currentImage == ""  && !file )  )   
                || (   ((category == "tickets" || category == "transportation") && file)  ) 
        ){
                console.log(postImage)
                console.log("REFF: " + "no reference")
                // return
                updatePostDocument("")
        }
        else if(   ((category == "tickets" || category == "transportation") && file)     )
        {
            // ################ upload the new file
            const storageRef = ref(storage, `files/${userid}/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on("state_changed",
            (snapshot) => {
                const progress =
                Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgresspercent(progress);
            },
            (error) => {
                alert(error);
            },
            () => {
                console.log(postImage)
                console.log("REFF: " + uploadTask.snapshot.ref)
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                // 2. save the image url in the post message
                collection(db, "users", userid, "posts" )
                console.log("DownloadURL:" +downloadURL);
                setImgUrl(downloadURL ? downloadURL : "")
                // return
                updatePostDocument(downloadURL)
            });
            }
            );
        }
        else{
            // ################ upload the new file
            const storageRef = ref(storage, `files/${userid}/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on("state_changed",
            (snapshot) => {
                const progress =
                Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgresspercent(progress);
            },
            (error) => {
                alert(error);
            },
            () => {
                console.log(postImage)
                console.log("REFF: " + uploadTask.snapshot.ref)
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                // 2. save the image url in the post message
                collection(db, "users", userid, "posts" )
                console.log("DownloadURL:" +downloadURL);
                setImgUrl(downloadURL ? downloadURL : "")
                // return
                updatePostDocument(downloadURL)
            });
        }
        )
    }

    }

        return (
            <>
            <div className="xch-main-header xch-container ">
                <div className="xch-header-flex">
                    <div className="xch-header-a"><Button variant="link" href="/myposts"><ArrowLeftCircleFill/> Posts</Button></div>
                    <h2 className="text-center xch-header-c">Edit Post</h2>
                    <div className="xch-header-b"></div>
                </div>
            </div>
            
            <Container>
                <Row className="xch-contact">
                    <Col>
                    <div>
                        <Form onSubmit={editPost}>
                                <Form.Text className="text-muted xch-label">
                                   Select Item Category
                                </Form.Text>
                                {/* elemnt 0 */}
                                <Form.Select 
                                    value={selectedCategory} 
                                    onChange={(e) => {checkCategory(e.target.value)}}
                                    // onChange={(e) => {setSelectedCategory(e.target.value)}}
                                    aria-label="Select post category">
                                    <option value="tickets">Tickets</option>
                                    <option value="costume">Costume</option>
                                    <option value="accommodation">Accommodation</option>
                                    <option value="transportation">Transportation</option>
                                </Form.Select>
                                <Form.Group className="mb-3" controlId="form.ControlTextarea1">
                                    <Form.Label className='xch-label'>Item Description&nbsp;<span className="xch-sm-txt">({charCount}/1000)</span></Form.Label>
                                    <Form.Control as="textarea" 
                                    value={newPostText ? newPostText: ""}
                                    onChange={ (e) => setNewPostText(e.target.value) }
                                    placeholder="Describe the item you have for sale, exchange or looking for. Give as much details as possible." rows={5} />
                                </Form.Group>
                                 {/* elemnt 2 */}
                                 
                                 <div className="xch-label xch-label-border">Post image &nbsp;<span className="xch-sm-txt">(Max-Size: 2MB)</span> </div>
                                 <div className="xch-img-edit">
                                    <div className="xch-img-icon">
                                        { (postImage != "") ? (<img src={postImage}/>) : (<></>)}
                                    </div>
                                    <div className="xch-img-name"></div>
                                    <div className="xch-img-size"></div>
                                    <div className="xch-img-action">
                                        {postImage != "" ? (
                                            <Button variant="link" size="sm" 
                                                onClick={() => {deleteCurrentImage(postImage, postRef);
                                                }}>Remove Image
                                            </Button>
                                        )
                                        : (<>
                                            <Button disabled variant="link" size="sm" >Remove Image</Button>
                                            </>)
                                        }
                                        
                                    </div>
                                 </div>
                                <Form.Group className="mb-3" controlId="form">
                                    {
                                        (!imgUrl && !postImage) &&
                                        <div className='outerbar'>
                                            <div className='innerbar' style={{ width: `${progresspercent}%` }}>{progresspercent}%</div>
                                        </div>
                                    }
                                    <Form.Label className=' xch-label '>Upload image &nbsp;<span className="xch-sm-txt">(Max-Size: 2MB)</span> </Form.Label>
                                    <div className="file-upload-wrapper">
                                    { canAddImage==1 ? ( <input type='file' /> ) : ( <input disabled type='file' /> ) }
                                    </div>
                                </Form.Group>
                                <input type="hidden" name="current_image" value={postImage} />

                                    <div className="xch-edit-footer">
                                        <div className="xch-flex-item xch-item-start">
                                            <Button variant="primary" type="submit">Preview</Button>
                                        </div>
                                        <div  className="xch-flex-item xch-item-end">
                                            <Button variant="link" onClick={() => {deletePost(postImage, postRef);}}>Delete Post</Button>
                                        </div>
                                    </div>
                                
                        </Form>
                        
                        {/* {
                            imgUrl &&
                            <img src={imgUrl} alt='uploaded file' height={200} />
                        } */}
                    </div>
                    </Col>
                </Row>    
            </Container>
            </>
        )


    

}

export default EditPost;